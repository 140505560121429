import { useEffect } from "react";
import googlePlay from "../images/google-play.png";
import appStore from "../images/app-store.png";
import glizmedlogo from "../images/glizmed-logo.svg";
import payment from "../images/payment.png";

function Footer(props) {
  return (
    <>
      <footer>
        <div className="newsletter">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4>Sign Up For Newsletter</h4>
                <p>
                  Join 60.000+ Subscribers and get a new discount coupon on
                  every Saturday.
                </p>
                <form>
                  <input type="email" name="email" placeholder="Email Id" autoComplete="off" />
                  <input
                    type="submit"
                    name="Subscribe"
                    placeholder="Subscribe"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-bg">
            <div className="footer-info">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <i className="fa-light fa-earth-americas"></i>
                  <div className="info-details">
                    <h4>ADDRESS</h4>
                    <p>9066 Green Lake Drive Chevy Chase, MD 20815</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <i className="fa-regular fa-phone"></i>
                  <div className="info-details">
                    <h4>WHATSAPP US</h4>
                    <h5>
                      <a href="#">(1800)-88-66-990</a>
                    </h5>
                    <p>contact@example.com</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 responsive-center">
                  <h3>Download the app now!</h3>
                  <div className="download-app">
                    <img src={googlePlay} />
                    <img src={appStore} />
                  </div>
                </div>
              </div>
            </div>

            <div className="main-footer">
              <div className="row">
                <div className="col-lg-4 col-md-12 foot-about">
                  <a href="#">
                    <img src={glizmedlogo} />
                  </a>
                  <p>
                    Glizmed is proud of being a best Pharmacy Online shops in
                    USA with high-quality medicines, supplements, healthcare
                    product...
                  </p>
                </div>
                <div className="col-lg-7 col-md-10">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <h4>Information</h4>
                      <ul>
                        <li>
                          <a href="#">Newsroom</a>
                        </li>
                        <li>
                          <a href="#">Sell Your Pharmacy</a>
                        </li>
                        <li>
                          <a href="#">Affiliate Program</a>
                        </li>
                        <li>
                          <a href="#">Careers</a>
                        </li>
                        <li>
                          <a href="#">Investor Relations</a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <h4>Categories</h4>
                      <ul>
                        <li>
                          <a href="#">Devices</a>
                        </li>
                        <li>
                          <a href="#">Family Care</a>
                        </li>
                        <li>
                          <a href="#">Fitness</a>
                        </li>
                        <li>
                          <a href="#">Lifestyle</a>
                        </li>
                        <li>
                          <a href="#">Personal care</a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <h4>Our services</h4>
                      <ul>
                        <li>
                          <a href="#">Shipping</a>
                        </li>
                        <li>
                          <a href="#">Returns</a>
                        </li>
                        <li>
                          <a href="#">Product Recalls</a>
                        </li>
                        <li>
                          <a href="#">Contact Us</a>
                        </li>
                        <li>
                          <a href="#">Site Map</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-md-2 pad-0">
                  <h4>Socials</h4>
                  <ul>
                    <li>
                      <a href="#">Facebook</a>
                    </li>
                    <li>
                      <a href="#">Twitter</a>
                    </li>
                    <li>
                      <a href="#">Linkedin</a>
                    </li>
                    <li>
                      <a href="#">Pinterest</a>
                    </li>
                    <li>
                      <a href="#">Email</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="our-payment">
              <div className="row">
                <div className="col-lg-12 text-center justify-content-center">
                  <p>Our Payment Partners:</p>
                  <img src={payment} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p>Copyright © 2020 Glizmed. All Rights Reserved.</p>
              </div>
              <div className="col-lg-6 col-md-12">
                <ul>
                  <li>
                    <a href="#">ABOUT US</a>
                  </li>
                  <li>
                    <a href="#">OUR STORES</a>
                  </li>
                  <li>
                    <a href="#">BLOG</a>
                  </li>
                  <li>
                    <a href="#">CONTACT</a>
                  </li>
                  <li>
                    <a href="#">FAQ'S</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
