
import { useEffect, useState, useCallback } from "react";
import { useParams} from 'react-router-dom';
import axios from "axios";
import{api} from "../utils/ApiUrl";
function Detail(props) {

  const params = useParams();

  const [medicineDetailArr, setMedicineDetailArr] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
   
    const data = {
      id:params.id
    }
    axios
       .post(api.postSearchMedicineById,data)
       .then((response) => {
         
       
         setMedicineDetailArr(response.data.result);
   
     
     })
       .catch((error) => console.log(error));
   
   
   }, []);
  return (
    <>
      <section className="shop_detail_main">
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="breadcrum-navigation">
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">Product</a>
                  </li>
                  <li>
                    <a href="#">Categories</a>
                  </li>
                  <li className="active">
                    <a href="#">Neuriva Brain Performance</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          <div className="main_product">
            <div className="row">
              <div className="col-lg-6">
                <img id="detailImgMain" src={medicineDetailArr[0] === undefined ||medicineDetailArr[0] === null ? "":`${api.baseUrl}`+`${medicineDetailArr[0].imagesArr[0]}` } />
                <div className="Shop_product">
                  <div className="product_item">
                    <img id="detailImg" src={medicineDetailArr[0] === undefined ||medicineDetailArr[0] === null ? "":`${api.baseUrl}`+`${medicineDetailArr[0].imagesArr[0]}` } />
                  </div>
                  <div className="product_item">
                    <img id="detailImg" src={medicineDetailArr[0] === undefined ||medicineDetailArr[0] === null ? "":`${api.baseUrl}`+`${medicineDetailArr[0].imagesArr[2]}` } />
                  </div>
                  <div className="product_item">
                    <img id="detailImg" src={medicineDetailArr[0] === undefined ||medicineDetailArr[0] === null ? "":`${api.baseUrl}`+`${medicineDetailArr[0].imagesArr[1]}` } />
                  </div>
                  <div className="product_item">
                    <img id="detailImg" src={medicineDetailArr[0] === undefined ||medicineDetailArr[0] === null ? "":`${api.baseUrl}`+`${medicineDetailArr[0].imagesArr[0]}` }/>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="Shop_product_inner">
                  <h1 className="product_title">{medicineDetailArr[0] === undefined ||medicineDetailArr[0]=== null ? "":medicineDetailArr[0].name }</h1>
                  <div className="main_star">
                    <div className="star-rating">
                      <input
                        type="radio"
                        id="5-stars"
                        name="rating"
                        value="5"
                      />
                      <label htmlFor="5-stars" className="star">
                        &#9733;
                      </label>
                      <input
                        type="radio"
                        id="4-stars"
                        name="rating"
                        value="4"
                      />
                      <label htmlFor="4-stars" className="star">
                        &#9733;
                      </label>
                      <input
                        type="radio"
                        id="3-stars"
                        name="rating"
                        value="3"
                      />
                      <label htmlFor="3-stars" className="star">
                        &#9733;
                      </label>
                      <input
                        type="radio"
                        id="2-stars"
                        name="rating"
                        value="2"
                      />
                      <label htmlFor="2-stars" className="star">
                        &#9733;
                      </label>
                      <input type="radio" id="1-star" name="rating" value="1" />
                      <label htmlFor="1-star" className="star">
                        &#9733;
                      </label>
                    </div>
                    <span>(5 reviews)</span>
                  </div>
                  <p className="price">Rs.{medicineDetailArr[0] === undefined ||medicineDetailArr[0]=== null ? "":medicineDetailArr[0].price }</p>
                  <p className="product_description">
                  {medicineDetailArr[0] === undefined ||medicineDetailArr[0]=== null ? "":medicineDetailArr[0].description }
                  </p>
                  <div className="d-flex">
                    <div className="Quantity">
                      <button className="button">+</button>
                      <input type="number" className="input" value="1" min="1" />
                      <button className="button">-</button>
                    </div>
                    <a
                      href=""
                      data-id="1"
                      data-quantity="1"
                      className="add-to-cart ajax product-1"
                    >
                      Add to Cart
                    </a>
                  </div>
                  <div className="product_meta">
                    <div className="inner_meta">
                      <span className="sku_wrapper">
                        SKU:
                        <span className="sku">N/A-242</span>
                      </span>
                    </div>
                    <div className="inner_meta">
                      <span className="sku_wrapper">
                        Category:
                        <span className="sku"> {medicineDetailArr[0] === undefined ||medicineDetailArr[0]=== null ? "":medicineDetailArr[0].category[0] }</span>
                      </span>
                    </div>
                    <div className="inner_meta">
                      <span className="sku_wrapper">
                        Tags:
                        <span className="sku">Organics,Vegetable</span>
                      </span>
                    </div>
                    <div className="inner_meta">
                      <span className="sku_wrapper2">
                        Share:
                        <span className="sku">
                          <ul className="d-flex">
                            <li>
                              <a href="#" target="_blank">
                                <i className="fa fa-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" target="_blank">
                                <i className="fa fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" target="_blank">
                                <i className="fa fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" target="_blank">
                                <i className="fa fa-google-plus"></i>
                              </a>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
}

export default Detail;
