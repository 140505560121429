import "../App.css";

import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../utils/ApiUrl";
import axios from "axios";
import Carousel from "react-multi-carousel";


function LabTests(props) {
    let navigate = useNavigate();

    const [allpathology, setAllpathology] = useState([]);
    const [allradiology, setAllradiology] = useState([]);
    // const [alllabTestOrder, setAlllabTestOrder] = useState("");

   useEffect(() => {
        const config = {
            headers: { Authorization: `Bearer` }
        };


        axios
            .get(api.baseUrl + "api/get-all-pathology")
            .then((response) => {


                setAllpathology(response.data.result);
                console.log(response.data.result)

            })
            .catch((error) => console.log(error));


        axios
            .get(api.baseUrl + "api/get-all-radiology")

            .then((response) => {


                setAllradiology(response.data.result);
                console.log(response.data.result)

            })
            .catch((error) => console.log(error));

            // axios
            // .get(api.baseUrl + "api/get-all-labTestOrders")

            // .then((response) => {


            //     setAlllabTestOrder(response.data.result);
            //     console.log(response.data.result)

            // })
            // .catch((error) => console.log(error));


    }, []);

    // const handleClick = (value) => {
     
 
    //     axios
    //         .get(`${api.baseUrl}` + `api/get-all-labTestOrders/${value._id}`)
    //         .then((response) => {


    //             setAlllabTestOrder (response.data.result);

    //         })
    //         .catch((error) => console.log(error));
    // }






    return (
        <div className="container">

            <section className="health-product">
                <div className="container">
                    <div className="health-product-bg">
                        <h3>Pathology</h3>
                        <div className="row">


                            {allradiology.map((value, i) => (

                                <div className="item col-lg-3 " key={i}>
                                    <div className="product-block2">
                                        <div className="product-transition">


                                        </div>
                                        <div className="caption2">

                                            <h6 className="product__title" >
                                                <span>{value.title}</span>
                                                <i class="fa fa-shield" aria-hidden="true"></i>
                                            </h6>


                                            <h6 className="product__title" >
                                                <span>{value.providedBy}</span>

                                            </h6>

                                            <span className="price">

                                                <h6 className="product__title" >
                                                    <span>{value.subTitle}</span>

                                                </h6>
                                            </span>

                                            <span className="price">
                                                <del aria-hidden="true">
                                                    <span className="amount">
                                                        <bdi>
                                                            <span className="currencySymbol">$</span>
                                                            124.98
                                                        </bdi>
                                                    </span>
                                                </del>
                                                <ins>
                                                    <span className="amount">
                                                        <bdi>
                                                            <span className="currencySymbol">Rs.</span>{value.mrp}
                                                        </bdi>
                                                    </span>

                                                </ins>


                                            </span>

                                            <span className="amount">

                                                <span className="currencySymbol"></span>{value.discountPercentage}

                                            </span>

                     <button className="bookNowBtn"  >Book Now</button>
                                        </div>
                                    </div>
                                </div>

                            ))}


                        </div>
                    </div>
                </div>
            </section>



    

            <section className="health-product">
                <div className="container">
                    <div className="health-product-bg">
                        <h3>Radiology</h3>
                        <div className="row">

                   {allradiology.map((value, i) => (

                                <div className="item col-lg-3  " key={i}>
                                    <div className="product-block2">
                                        <div className="product-transition">


                                        </div>
                                        <div className="caption2">

                                            <h6 className="product__title" >
                                                <span>{value.title}</span>
                                                <i class="fa fa-shield" aria-hidden="true">SAFE</i>
                                            </h6>




                                            <h6 className="product__title" >
                                                <span>{value.providedBy}</span>

                                            </h6>

                                            <span className="price">

                                                <h6 className="product__title" >
                                                    <span>{value.subTitle}</span>

                                                </h6>
                                            </span>

                                            <span className="price">
                                                <del aria-hidden="true">
                                                    <span className="amount">
                                                        <bdi>
                                                            <span className="currencySymbol">$</span>
                                                            124.98
                                                        </bdi>
                                                    </span>
                                                </del>
                                                <ins>
                                                    <span className="amount">
                                                        <bdi>
                                                            <span className="currencySymbol">Rs.</span>{value.mrp}
                                                        </bdi>
                                                    </span>

                                                </ins>
                                            </span>

                                            <span className="amount">

                                                <span className="currencySymbol"></span>{value.discountPercentage}

                                            </span>

                                            <button className="bookNowBtn">Book Now</button>
                                        </div>
                                    </div>
                                </div>

                            ))}




                        </div>
                    </div>
                </div>
            </section>


               {/* <section className="health-product">
                <div className="container">
                    <div className="health-product-bg">
                        <h3>form</h3>
                        <div className="row">

                               {alllabTestOrder.map((value, i) => (

                                <div className="item   " key={i}>
                                    <span>ghdfgh</span>{value.name}
                                
                                </div>

                            ))}




                        </div>
                    </div>
                </div>
            </section> */}


        </div>










    )
}

export default LabTests;