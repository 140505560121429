import "../App.css";

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import img1 from "../images/h1-news01.png";
import img2 from "../images/h1-new02.png";
import img3 from "../images/h1-new03.png";
import slider1 from "../images/h3-slider01.jpg";
import slider2 from "../images/h2-slider02.jpg";
import slider3 from "../images/h3-slider01.jpg";
 
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useAuthDataContext } from "./AuthDataProvider";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddedToCartModal from "../utils/AddedToCartModal";

import { api } from "../utils/ApiUrl";

function Dashboard(props) {

  let navigate = useNavigate();
  const { fnAddToCart, setShowAddedToCart, showAddedToCart } = useAuthDataContext();

  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    smalldesktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    smalldesktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const [brandNameArr, setbrandNameArr] = useState([]);
  const [allMedicinesArr, setAllMedicinesArr] = useState([]);
  const [PopularCategoriesArr, setPopularCategoriesArr] = useState([]);
 



  useEffect(() => {
    
    axios
      .get(api.getAllBrandName)
      .then((response) => {

       
        setbrandNameArr(response.data.result);

      })
      .catch((error) => console.log(error));



    axios
      .get(api.getAllMedicine)
      // .get("http://localhost:4889/api/get-all-tags-dashboard")
      .then((response) => {

 
        setAllMedicinesArr(response.data.result);

      })
      .catch((error) => console.log(error));


    axios
      .get(api.getAllCategory)
      .then((response) => {


        setPopularCategoriesArr(response.data.result);

      })
      .catch((error) => console.log(error));

  }, []);


  
  return (
    <>
      <div className="container">
        <div className="feature-wrap">
          <ul>
            <li>
              <i className="fa-light fa-prescription-bottle-medical"></i>
              <h4>
                Medicine <span>Over 25000 products</span>
              </h4>
            </li>
            <li>
              <i className="fa-light fa-prescription-bottle-medical"></i>
              <h4>
                Medicine <span>Over 25000 products</span>
              </h4>
            </li>
            <li>
              <i className="fa-light fa-prescription-bottle-medical"></i>
              <h4>
                Medicine <span>Over 25000 products</span>
              </h4>
            </li>
            <li>
              <i className="fa-light fa-prescription-bottle-medical"></i>
              <h4>
                Medicine <span>Over 25000 products</span>
              </h4>
            </li>
            <li>
              <i className="fa-light fa-prescription-bottle-medical"></i>
              <h4>
                Medicine <span>Over 25000 products</span>
              </h4>
            </li>
          </ul>
        </div>
      </div>

      <section className="main-banner">
        <div id="home-banner">
          <Carousel
            swipeable={false}
            arrows={true}
            draggable={false}
            showDots={false}
            responsive={responsive1}
            infinite={true}
            autoPlaySpeed={100}
            dotListclassName=""
            renderDotsOutside={true}
            afterChange={(previousSlide, { currentSlide, onMove }) => {
            
            }}
          >
            <div className="item">
              <img src={slider1} alt="" className="banner-bg" />
              <div className="home-banner-content">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      <img src={img1} alt="img heree" />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h2>
                        Flat 25% Off <span>Medicine Order</span>
                      </h2>
                      <div className="row mt-3">
                        <div className="col-lg-6 d-flex">
                          <i className="fa-light fa-prescription-bottle-medical"></i>
                          <h4>
                            Win Big Offers
                            <br /> Every Day
                          </h4>
                        </div>
                        <div className="col-lg-6 d-flex">
                          <i className="fa-light fa-prescription-bottle-medical"></i>
                          <h4>
                            Win Big Offers
                            <br /> Every Day
                          </h4>
                        </div>
                      </div>
                      <a href="#" className="banner-cta">
                        <i className="fa-light fa-right-long"></i> Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <img src={slider2} alt="" className="banner-bg" />
              <div className="home-banner-content">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      <img src={img2} />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h2>
                        Flat 25% Off <span>Medicine Order</span>
                      </h2>
                      <div className="row mt-3">
                        <div className="col-lg-6 d-flex">
                          <i className="fa-light fa-prescription-bottle-medical"></i>
                          <h4>
                            Win Big Offers
                            <br /> Every Day
                          </h4>
                        </div>
                        <div className="col-lg-6 d-flex">
                          <i className="fa-light fa-prescription-bottle-medical"></i>
                          <h4>
                            Win Big Offers
                            <br /> Every Day
                          </h4>
                        </div>
                      </div>
                      <a href="#" className="banner-cta">
                        <i className="fa-light fa-right-long"></i> Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <img src={slider3} alt="" className="banner-bg" />
              <div className="home-banner-content">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      <img src={img3} />
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h2>
                        Flat 25% Off <span>Medicine Order</span>
                      </h2>
                      <div className="row mt-3">
                        <div className="col-lg-6 d-flex">
                          <i className="fa-light fa-prescription-bottle-medical"></i>
                          <h4>
                            Win Big Offers
                            <br /> Every Day
                          </h4>
                        </div>
                        <div className="col-lg-6 d-flex">
                          <i className="fa-light fa-prescription-bottle-medical"></i>
                          <h4>
                            Win Big Offers
                            <br /> Every Day
                          </h4>
                        </div>
                      </div>
                      <a href="#" className="banner-cta">
                        <i className="fa-light fa-right-long"></i> Shop Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>





     


      <section className="health-product">
        <div className="container">
          <div className="health-product-bg">
            <h3>Daily Deal Of The Day</h3>
            <div className="row">
              <div className="col-lg-12">
                <div id="health-slider">
                  <Carousel
                    swipeable={false}
                    arrows={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={100}
                    dotListclassName=""
                    renderDotsOutside={true}
                    className="carouselProducts"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                    
                    }}
                  >

                    {allMedicinesArr.map((value, i) => (

                      <div className="item" key={i}>
                        <div className="product-block">
                          <div className="product-transition">
                            <div className="product-img-wrap top-to-bottom">
                              <div className="inner">
                                <div className="product-image">
                             
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                                <div className="product-image second-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                              </div>
                            </div>
                            <div className="group-label">
                              <span className="stock-label instock">
                                <span>In Stock</span>
                              </span>
                              <span className="onsale">-41% Off </span>
                            </div>
                            <div className="group-action">
                              <div className="shop-action">
                                <div className="add-wishlist-button">
                                  <a href="#">Add to wishlist</a>
                                  <div
                                    className="ajax-loading opal-loading-wislist"
                                    style={{ visibility: "hidden" }}
                                  ></div>
                                </div>
                                <div className="compare-button">
                                  <a href="#">Compare</a>
                                </div>
                                <div className="quick-view-button">
                                  <a href="#">Quick View</a>
                                </div>
                              </div>
                            </div>
                            <a
                              href="#"
                              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                            ></a>
                          </div>
                          <div className="caption">
                            <div className="posted_in">
                              <a href="#" rel="tag">
                                Diabetes
                              </a>
                              ,{" "}
                              <a href="#" rel="tag">
                                Fitness
                              </a>
                            </div>
                            <h3 className="product__title" onClick={() => { navigate(`/home/detail/${value._id}`) }}>
                              <span>{value.name}</span>
                                        {/* <span>{value.tagsName}</span> */}
                            </h3>
                            <div className="star-rating">
                              <input
                                type="radio"
                                id="5-stars"
                                name="rating"
                                value="5"
                              />
                              <label htmlFor="5-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="4-stars"
                                name="rating"
                                value="4"
                              />
                              <label htmlFor="4-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="3-stars"
                                name="rating"
                                value="3"
                              />
                              <label htmlFor="3-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="2-stars"
                                name="rating"
                                value="2"
                              />
                              <label htmlFor="2-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="1-star"
                                name="rating"
                                value="1"
                              />
                              <label htmlFor="1-star" className="star">
                                &#9733;
                              </label>
                            </div>

                            <span className="price">
                              <del aria-hidden="true">
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">$</span>
                                    124.98
                                  </bdi>
                                </span>
                              </del>
                              <ins>
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">Rs.</span>{value.price}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                            <div className="product-footer">
                              <div className="shop-action">
                                <span
                                  onClick={() => { fnAddToCart(value) }}
                                  data-quantity="1"
                                  className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  data-product_id="258"
                                  data-product_sku="N/A-258"
                                  aria-label="Add “Organika Salmon Collagen” to your cart"
                                  rel="nofollow"
                                >
                                  Add to cart
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature-brand">
        <div className="container">
          <div className="feature-brand-bg">
            <h3>Feature Brand</h3>
            <div className="row">
              <div className="col-lg-12">
                <div id="feature-slider">
                  <Carousel
                    swipeable={false}
                    arrows={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive2}
                    infinite={true}
                    autoPlaySpeed={100}
                    dotListclassName=""
                    renderDotsOutside={true}
                    itemclassName=""
                    className="brandNameDashboard"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                
                    }}
                  >
                    {brandNameArr.map((value, i) => (
                      <div className="" key={i}>
                        <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                      </div>
                    ))}




                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="health-product">
        <div className="container">
          <div className="health-product-bg">
            <h3>Homeopathy </h3>
            <div className="row">
              <div className="col-lg-12">
                <div id="health-slider">
                  <Carousel
                    swipeable={false}
                    arrows={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={100}
                    dotListclassName=""
                    renderDotsOutside={true}
                    className="carouselProducts"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                   
                    }}
                  >

                    {allMedicinesArr.map((value, i) => (

                      <div className="item" key={i}>
                        <div className="product-block">
                          <div className="product-transition">
                            <div className="product-img-wrap top-to-bottom">
                              <div className="inner">
                                <div className="product-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                                <div className="product-image second-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                              </div>
                            </div>
                            <div className="group-label">
                              <span className="stock-label instock">
                                <span>In Stock</span>
                              </span>
                              <span className="onsale">-41% Off </span>
                            </div>
                            <div className="group-action">
                              <div className="shop-action">
                                <div className="add-wishlist-button">
                                  <a href="#">Add to wishlist</a>
                                  <div
                                    className="ajax-loading opal-loading-wislist"
                                    style={{ visibility: "hidden" }}
                                  ></div>
                                </div>
                                <div className="compare-button">
                                  <a href="#">Compare</a>
                                </div>
                                <div className="quick-view-button">
                                  <a href="#">Quick View</a>
                                </div>
                              </div>
                            </div>
                            <a
                              href="#"
                              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                            ></a>
                          </div>
                          <div className="caption">
                            <div className="posted_in">
                              <a href="#" rel="tag">
                                Diabetes
                              </a>
                              ,{" "}
                              <a href="#" rel="tag">
                                Fitness
                              </a>
                            </div>
                            <h3 className="product__title" onClick={() => { navigate(`/home/detail/${value._id}`) }}>
                              <span>{value.name}</span>
                                        {/* <span>{value.tagsName}</span> */}
                            </h3>
                            <div className="star-rating">
                              <input
                                type="radio"
                                id="5-stars"
                                name="rating"
                                value="5"
                              />
                              <label htmlFor="5-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="4-stars"
                                name="rating"
                                value="4"
                              />
                              <label htmlFor="4-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="3-stars"
                                name="rating"
                                value="3"
                              />
                              <label htmlFor="3-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="2-stars"
                                name="rating"
                                value="2"
                              />
                              <label htmlFor="2-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="1-star"
                                name="rating"
                                value="1"
                              />
                              <label htmlFor="1-star" className="star">
                                &#9733;
                              </label>
                            </div>

                            <span className="price">
                              <del aria-hidden="true">
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">$</span>
                                    124.98
                                  </bdi>
                                </span>
                              </del>
                              <ins>
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">Rs.</span>{value.price}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                            <div className="product-footer">
                              <div className="shop-action">
                                <span
                                  onClick={() => { fnAddToCart(value) }}
                                  data-quantity="1"
                                  className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  data-product_id="258"
                                  data-product_sku="N/A-258"
                                  aria-label="Add “Organika Salmon Collagen” to your cart"
                                  rel="nofollow"
                                >
                                  Add to cart
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="health-product">
        <div className="container">
          <div className="health-product-bg">
            <h3>New arrivals Glizmed </h3>
            <div className="row">
              <div className="col-lg-12">
                <div id="health-slider">
                  <Carousel
                    swipeable={false}
                    arrows={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={100}
                    dotListclassName=""
                    renderDotsOutside={true}
                    className="carouselProducts"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                    
                    }}
                  >

                    {allMedicinesArr.map((value, i) => (

                      <div className="item" key={i}>
                        <div className="product-block">
                          <div className="product-transition">
                            <div className="product-img-wrap top-to-bottom">
                              <div className="inner">
                                <div className="product-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                                <div className="product-image second-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                              </div>
                            </div>
                            <div className="group-label">
                              <span className="stock-label instock">
                                <span>In Stock</span>
                              </span>
                              <span className="onsale">-41% Off </span>
                            </div>
                            <div className="group-action">
                              <div className="shop-action">
                                <div className="add-wishlist-button">
                                  <a href="#">Add to wishlist</a>
                                  <div
                                    className="ajax-loading opal-loading-wislist"
                                    style={{ visibility: "hidden" }}
                                  ></div>
                                </div>
                                <div className="compare-button">
                                  <a href="#">Compare</a>
                                </div>
                                <div className="quick-view-button">
                                  <a href="#">Quick View</a>
                                </div>
                              </div>
                            </div>
                            <a
                              href="#"
                              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                            ></a>
                          </div>
                          <div className="caption">
                            <div className="posted_in">
                              <a href="#" rel="tag">
                                Diabetes
                              </a>
                              ,{" "}
                              <a href="#" rel="tag">
                                Fitness
                              </a>
                            </div>
                            <h3 className="product__title" onClick={() => { navigate(`/home/detail/${value._id}`) }}>
                              <span>{value.name}</span>
                                        {/* <span>{value.tagsName}</span> */}
                            </h3>
                            <div className="star-rating">
                              <input
                                type="radio"
                                id="5-stars"
                                name="rating"
                                value="5"
                              />
                              <label htmlFor="5-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="4-stars"
                                name="rating"
                                value="4"
                              />
                              <label htmlFor="4-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="3-stars"
                                name="rating"
                                value="3"
                              />
                              <label htmlFor="3-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="2-stars"
                                name="rating"
                                value="2"
                              />
                              <label htmlFor="2-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="1-star"
                                name="rating"
                                value="1"
                              />
                              <label htmlFor="1-star" className="star">
                                &#9733;
                              </label>
                            </div>

                            <span className="price">
                              <del aria-hidden="true">
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">$</span>
                                    124.98
                                  </bdi>
                                </span>
                              </del>
                              <ins>
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">Rs.</span>{value.price}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                            <div className="product-footer">
                              <div className="shop-action">
                                <span
                                  onClick={() => { fnAddToCart(value) }}
                                  data-quantity="1"
                                  className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  data-product_id="258"
                                  data-product_sku="N/A-258"
                                  aria-label="Add “Organika Salmon Collagen” to your cart"
                                  rel="nofollow"
                                >
                                  Add to cart
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="health-product">
        <div className="container">
          <div className="health-product-bg">
            <h3> Trending Products</h3>
            <div className="row">
              <div className="col-lg-12">
                <div id="health-slider">
                  <Carousel
                    swipeable={false}
                    arrows={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={100}
                    dotListclassName=""
                    renderDotsOutside={true}
                    className="carouselProducts"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                 
                    }}
                  >

                    {allMedicinesArr.map((value, i) => (

                      <div className="item" key={i}>
                        <div className="product-block">
                          <div className="product-transition">
                            <div className="product-img-wrap top-to-bottom">
                              <div className="inner">
                                <div className="product-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                                <div className="product-image second-image">
                                  <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                                </div>
                              </div>
                            </div>
                            <div className="group-label">
                              <span className="stock-label instock">
                                <span>In Stock</span>
                              </span>
                              <span className="onsale">-41% Off </span>
                            </div>
                            <div className="group-action">
                              <div className="shop-action">
                                <div className="add-wishlist-button">
                                  <a href="#">Add to wishlist</a>
                                  <div
                                    className="ajax-loading opal-loading-wislist"
                                    style={{ visibility: "hidden" }}
                                  ></div>
                                </div>
                                <div className="compare-button">
                                  <a href="#">Compare</a>
                                </div>
                                <div className="quick-view-button">
                                  <a href="#">Quick View</a>
                                </div>
                              </div>
                            </div>
                            <a
                              href="#"
                              className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                            ></a>
                          </div>
                          <div className="caption">
                            <div className="posted_in">
                              <a href="#" rel="tag">
                                Diabetes
                              </a>
                              ,{" "}
                              <a href="#" rel="tag">
                                Fitness
                              </a>
                            </div>
                            <h3 className="product__title">
                              <a href="#">{value.name}</a>
                            </h3>
                            <div className="star-rating">
                              <input
                                type="radio"
                                id="5-stars"
                                name="rating"
                                value="5"
                              />
                              <label htmlFor="5-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="4-stars"
                                name="rating"
                                value="4"
                              />
                              <label htmlFor="4-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="3-stars"
                                name="rating"
                                value="3"
                              />
                              <label htmlFor="3-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="2-stars"
                                name="rating"
                                value="2"
                              />
                              <label htmlFor="2-stars" className="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="1-star"
                                name="rating"
                                value="1"
                              />
                              <label htmlFor="1-star" className="star">
                                &#9733;
                              </label>
                            </div>

                            <span className="price">
                              <del aria-hidden="true">
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">$</span>
                                    124.98
                                  </bdi>
                                </span>
                              </del>
                              <ins>
                                <span className="amount">
                                  <bdi>
                                    <span className="currencySymbol">Rs.</span>{value.price}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                            <div className="product-footer">
                              <div className="shop-action">
                                <span
                                  onClick={() => { fnAddToCart(value) }}
                                  data-quantity="1"
                                  className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                  data-product_id="258"
                                  data-product_sku="N/A-258"
                                  aria-label="Add “Organika Salmon Collagen” to your cart"
                                  rel="nofollow"
                                >
                                  Add to cart
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="popular-categories">
        <div className="container">
          <div className="popular-categories-bg">
            <h3>Popular Categories</h3>
            <div className="row">
              <div className="col-lg-12">
                <div id="popular-categories-slider">
                  <Carousel
                    swipeable={false}
                    arrows={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive2}
                    infinite={true}
                    autoPlaySpeed={100}
                    dotListclassName=""
                    renderDotsOutside={true}
                    itemclassName=""
                    className="brandNameDashboard"
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                   
                    }}
                  >
                    {PopularCategoriesArr.map((value, i) => (
                      <div className="popular-categories-inner" key={i}>
                        <img src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
                        <h4>{value.categoryName}</h4>
                      </div>
                    ))}

                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddedToCartModal
        show={showAddedToCart}
        setShow={setShowAddedToCart}

      />
    
    </>
  );
}

export default Dashboard;
