import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import logo from "../images/glizmed-logo.svg";
import { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import axios from "axios";
import {api} from "../utils/ApiUrl";
import tickMark from "../images/tickMark.jpg";
//PROFILE UPDATED MODAL
export default function UploadPresModal(props) {

  const handleClose = () => props.setShow(false);

  let navigate = useNavigate();
  const [fileOne, setFileOne] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [componentShown,setComponentShown]= useState("")
 
  

function handleCancel(){
  setFileOne("");
  props.setShow(false)
}

function submitform() {
 
  const data = new FormData();
  var phoneNumber=localStorage.getItem("phoneNumber")
  data.append("phoneNumber", phoneNumber);
  data.append("cart", []);


  for (let i = 0; i < fileOne.length; i++) {
    data.append("fileOne", fileOne[i]);
  }

  
  if(fileOne.length>0){

    
    axios
    .post(api.postCustomerPrescriptionUpload, data)
    .then((response) =>{ 


      if(response.data.status == true) {
       
        setComponentShown("success");
        setTimeout(function() { setComponentShown(""); props.setShow(false) }, 1000);

      } else {

 
      }

  })
    .catch((error) => console.log(error));
  }
  else{
    setErrorFlag(true)
  }
  
}


//   useEffect(() => {
// var loginFlag=localStorage.getItem("loginFlag")

// if(loginFlag === null){

//   alert("Please login first");
// navigate("/login");
// } 
// }, []);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        style={{
          top: "20%",
        }}
      >
        <Modal.Header   >
          <Modal.Title  onClick={handleCancel} className="prestitle"> <i class="fa fa-times"   aria-hidden="true"></i></Modal.Title>
        </Modal.Header   >
        <Modal.Body>
          {componentShown == "" ? <div className="parentProfileUpdatedDiv">
        <form encType="multipart/form-data">
        
        <label className="uploadprs">
          Upload prescription and tell us what you need.
         <label htmlFor="file1"  className="uploadnow" > Upload now <i class="fa-solid fa-arrow-right"></i>
         <input
         style={{display:"none",visibility:"none"}}
           type="file"
           id="file1"
           name="fileOne"
           multiple
           onChange={(e) => {
             setFileOne(e.target.files);
             setErrorFlag(false);
           }}
         />
         </label>
       </label>
       <br />
       <div>
        {errorFlag==false? <span className="text-primary">{fileOne.length} file selected</span>:<span className="text-danger">Please select atleast one Image</span>}
   
       </div>
       <br />
       <div className="inputfield" >
      
       <input type="button" value="Cancel" className="inputbtn" onClick={handleCancel}  />

       <input type="button" value="Submit" className="inputbtn" onClick={submitform} />

        </div>

      
       </form>
       
          </div> : <div className="text-center"> 
          <p className="text-primary"> SUCCESS</p>
          <img src={tickMark}  alt="document" className="glizmedLogoProfileUpdateModal"/ >
            </div>}
         

          
        </Modal.Body>
      </Modal>
    </div>
  );
}



