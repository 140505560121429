import "../App.css";

import React from "react";
import { useAuthDataContext } from "./AuthDataProvider";
import { useState, useEffect } from "react";
import axios from "axios";
import {api} from "../utils/ApiUrl";

function SearchResult(props) {

    const { searchParam, setSearchParam ,fnAddToCart} = useAuthDataContext();
    const [searchArr, setSearchArr] = useState([]);

  useEffect(() => {
  
    // var checkLoginStatus = sessionStorage.getItem("loginStatus");
    // if (checkLoginStatus === null || checkLoginStatus === undefined || checkLoginStatus === "") {
    //     navigate("/");
    // }

     var data = {searchString :searchParam}
    
    axios
      .post(api.postSearchMedicine, data)
      .then((response) => {
        
       
        setSearchArr(response.data.result);
    
    })
      .catch((error) => console.log(error));
  }, [searchParam]);

  
  return (
    <>
      <main>
        <section className="search-result">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <h1>Search Result</h1>
                <div className="row">
                  <div className="col-lg-12 col-md-12">

                  {searchArr.map((value, i) => (
  <div className="product-details" key={i}>
  <div className="product-itemdetails row ng-star-inserted">
    <div className="leftside-icons col-2 p-0">
      <a className="product-item-photo" title="">
        <img className="pro-img" src={`${api.baseUrl}`+`${value.imagesArr[0]}`} />
      </a>
    </div>
    <div className="rightside-details col pr-0">
      <div className="row m-0">
        <div className="product-item-name col-12 pl-0">
          <span> {value.name}</span>
        </div>
        <div className="catag-name col-12 pl-0">
          <a href="#" rel="tag">
            {value.category[0]}
          </a>
           {" "}
          <a href="#" rel="tag">
         / {value.brandName}
          </a>
        </div>
      </div>
      <div className="row m-0 mt-3">
        <div className="item-prices col-6 p-0">
          <div className="discount-val">
            <span className="best-price">Best price*</span>
            <span id="row_itmdiscprice_930167">Rs. {value.price} /-</span>
          </div>
        </div>

        <div className="col-6">
          <div className="shop-action">
            <a
              href="#"
              data-quantity="1"
              className="button product_type_simple add_to_cart_button ajax_add_to_cart"
              data-product_id="258"
              data-product_sku="N/A-258"
              aria-label="Add “Organika Salmon Collagen” to your cart"
              rel="nofollow"
              onClick={()=> fnAddToCart(value)}
            >
              Add to cart
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
                   ))}
                  
                  </div>
                </div>
              </div>

              <div className="col-lg-3 sticky-top"></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default React.memo(SearchResult);
