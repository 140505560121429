import "../App.css";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { api } from "../utils/ApiUrl";
import Modal from "react-bootstrap/Modal";
function UploadPrescription(props) {
  
  const handleClose = () => props.setShow(false);
  
  let navigate = useNavigate();
  const [fileOne, setFileOne] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);

 

  function handleCancel(){
    setFileOne("");
    props.setShow(false)
  }
  function submitform() {
    const data = new FormData();
var number = localStorage.getItem("phoneNumber");
    data.append("phoneNumber", number);
    data.append("cart", []);
    data.append("medicineArr", []);

    for (let i = 0; i < fileOne.length; i++) {
      data.append("fileOne", fileOne[i]);
    }

    axios
      .post(api.postCustomerPrescriptionUpload, data)
      .then((response) => {


        if (response.data.status == true) {
          navigate("/home/dashboard");
          setTimeout(function() {props.setShow(false) }, 1000);
        } else {
          setErrorFlag(true)

        }
       
      })
      .catch((error) => console.log(error));
  }


  useEffect(() => {
    var loginFlag = localStorage.getItem("loginFlag")

    if (loginFlag === null) {

      alert("Please login first");
      navigate("/login");
    }
  }, []);


  return (

    <div className="uploadprescription">
     <form encType="multipart/form-data">

        {/* <label>
         upload Images of prescription (maximum 4 allowed at once):
          <input
            type="file"
            name="fileOne"
            multiple
            onChange={(e) => {
              setFileOne(e.target.files);
            }}
          />
        </label> */}

        <Modal.Title onClick={handleCancel} className="prestitle"> <i className="fa fa-times" aria-hidden="true"></i></Modal.Title>

        <label className="uploadprs">
          Upload prescription and tell us what you need.
          <label htmlFor="file1" className="uploadnow" > Upload now <i className="fa-solid fa-arrow-right"></i>
            <input
              style={{ display: "none", visibility: "none" }}
              type="file"
              id="file1"
              name="fileOne"
              multiple
              onChange={(e) => {
                setFileOne(e.target.files);
                setErrorFlag(false);
              }}
            />
          </label>
        </label>
        <br />
        <div className="inputfield" >

        <input type="button" value="Cancel" className="inputbtn" onClick={handleCancel}  />

          <input type="button" value="Submit" className="inputbtn" onClick={submitform} />

        </div>

      </form>

    </div>

  )
}

export default UploadPrescription;
