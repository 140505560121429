import React, { createContext, useState, useContext, useEffect } from "react";
import {api} from "../utils/ApiUrl";
import axios from "axios";
export const AuthDataContext = createContext(null);

const AuthDataProvider = (props) => {
  const [authVariable, setAuthVariable] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [checkoutFlag, setCheckoutFlag] = useState("");
  const [showAddedToCart,setShowAddedToCart] = useState(false);

const fnAddToCart = (value) => {
 // handle click event of the Add button
 var cartList =[];
 cartList =JSON.parse(localStorage.getItem("cart")) === null ? []:JSON.parse(localStorage.getItem("cart"));

 if(cartList.length == 0){



  cartList.push({ name: value.name, price: parseInt(value.price)  , image:value.imagesArr[0],brandName:value.brandName,medicineId:value._id,count:"1",category:value.category})
 
} else {



  var boolCheck=false;
  cartList.forEach(element => {
 
    if(element.medicineId === value._id){
  
      boolCheck=true;
    }
  });

if(boolCheck == false){
  cartList.push({ name: value.name, price: parseInt(value.price)  , image:value.imagesArr[0],brandName:value.brandName,medicineId:value._id,count:"1",category:value.category})

}
 }


  localStorage.setItem("cart",JSON.stringify(cartList));

  var checkLoginStatus= localStorage.getItem("loginFlag");
	if(checkLoginStatus === "true") { 


    var cartList =[];
    cartList =JSON.parse(localStorage.getItem("cart")) === null ? []:JSON.parse(localStorage.getItem("cart"));
		var data ={
			phoneNumber:localStorage.getItem("phoneNumber"),
			cart:cartList
		}

	
		axios
		.post(api.postCustomerCart, data)
		.then((response) => {})
		.catch((error) => console.log(error) );

	}

  setShowAddedToCart(true);

  setTimeout(function() {setShowAddedToCart(false)}, 1000);
  
}
 
  useEffect(() => {

   setAuthVariable(false);

  }, []);


  const ContextValue = {
    authVariable: authVariable,
    setAuthVariable: setAuthVariable,
    searchParam: searchParam,
    setSearchParam: setSearchParam,
    fnAddToCart:fnAddToCart,
    checkoutFlag:checkoutFlag,
    setCheckoutFlag:setCheckoutFlag,
    showAddedToCart:showAddedToCart,
    setShowAddedToCart:setShowAddedToCart
  };

  return <AuthDataContext.Provider value={ContextValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
