import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Checkout from "./Checkout";
import Login from "./LoginSignUp/Login";
import SearchResult from "./SearchResult";
import PrivateRoute from "./PrivateRoute";
import Error from "./Error";
import Detail from "./Detail";
import Register from "./Register";
import Cart from "./Cart";
import OrderMedicine from "./OrderMedicine";

import CustomerDetailForm from "./CustomerDetailForm";
import UploadPrescription from "./UploadPrescription";
import HealthProducts from "./HealthProducts";
import MyOrders from "./MyOrders";
import LabTests from "./LabTests";
import Test from "./Test";

const Router = () => {
  let navigate = useNavigate();

  return (
    <>
      <Routes>
      <Route path="/" element={<Test />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/customerDetail" element={<CustomerDetailForm />} />
        <Route path="/uploadPrescription" element={<UploadPrescription />} />
        <Route path="/home" element={<PrivateRoute />}>
        <Route path="myOrders" element={<MyOrders />} />
        <Route path="ordermedicine" element={<OrderMedicine />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="detail/:id" element={<Detail />} />
          <Route path="search" element={<SearchResult />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="cart" element={<Cart />} />
          <Route path="healthProducts" element={<HealthProducts />} />
          <Route path="labTests" element={<LabTests />} />
          
          
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default Router;
