import "../App.css";

import React from "react";
import { useState ,useEffect} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import  { Carousel } from 'react-responsive-carousel';

import img1 from "../images/h1-news01.png";
import img2 from "../images/h1-new02.png";
import img3 from "../images/h1-new03.png";
import { api } from "../utils/ApiUrl";

function MedicineDetailCarousel(props) {

  


   
  return (<div className="">

<Carousel>
    {props.arr.map((value) =>(
         <div >
         <img src={`${api.baseUrl}`+`${value}`} />
           
         </div>

    ))}
               
                
            </Carousel>
  </div>);
}

export default MedicineDetailCarousel;
