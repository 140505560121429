import "../App.css";

import React from "react";

function Checkout(props) {

  return <div className="">checkoutttt
 
  </div>;
}

export default React.memo(Checkout);
