import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

import axios from "axios";
import glizmedlogo from "../images/glizmed-logo.svg";
import ProfileUpdatedModal from "../utils/ProfileUpdatedModal";
import OrderPlacedModal from "../utils/OrderPlacedModal";
import AddressErrorModal from "../utils/AddressErrorModal";
import { useAuthDataContext } from "./AuthDataProvider";
import { api } from "../utils/ApiUrl";
//below is with verification link in email with firebase
function CustomerDetailForm() {
  const { checkoutFlag } = useAuthDataContext();
  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeList, setPincodeList] = useState([]);
  const [district, setDistrict] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [state, setState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [email, setEmail] = useState("");
  const [emailVerifyFlag, setEmailVerifyFlag] = useState("false");
  const [emailOtp, setEmailOtp] = useState("");
  const [show, setShow] = useState(false);
  const [showOrderPlaced, setShowOrderPlaced] = useState(false);
  const [showAddressError, setShowAddressError] = useState(false);


  useEffect(() => {
    axios
      .get(api.getAllStates)
      .then((responseStates) => {


        setStateList(responseStates.data.result);
      })

    if (localStorage.getItem("phoneNumber") !== null) {
      setPhoneNumber(localStorage.getItem("phoneNumber"));

      //getVerificationStatus of email id of this user by phonenumber
      axios
        .get(api.getVerificationStatus + localStorage.getItem("phoneNumber"))
        .then((response) => {


          if (response.data.result[0].emailStatus === "true") {
            setEmailVerifyFlag("true");


            // get customer by phonenumber
            axios
              .get(api.getCustomerByPhoneNumber + localStorage.getItem("phoneNumber"))
              .then((responseCus) => {



                setFirstName(responseCus.data.result[0].firstName);
                setLastName(responseCus.data.result[0].lastName);
                setDob(responseCus.data.result[0].dob);
                setPhoneNumber(localStorage.getItem("phoneNumber"));
                setLandmark(responseCus.data.result[0].landmark);
                setAddress(responseCus.data.result[0].address);

        

                setPincode(responseCus.data.result[0].pincode);
                setDistrict(responseCus.data.result[0].city);
                setState(responseCus.data.result[0].state);
                setEmail(responseCus.data.result[0].email);

                //if user verified the email but press refresh or back button instead of submit so customer database still has email as "" hence we again let user add email here
                if (responseCus.data.result[0].email === "") {
                  setEmailVerifyFlag("false")
                }
                pincodeApiCall(responseCus);


              })

          } else {
            setEmailVerifyFlag("false")
          }
        })
        .catch((error) => { console.log("Axios catchhhhh"); console.log(error) });
    }

  }, []);


  const pincodeApiCall = (responseCus) => {
    axios
      .get(api.getAllStates)
      .then((responseStates) => {


        setStateList(responseStates.data.result);


        var data = {
          stateName: responseCus.data.result[0].state
        }
        axios
          .post(api.postAllDistrictName, data)
          .then((response) => {


            setDistrictList(response.data.result[0].districtName);


            var data2 = {
              districtName: responseCus.data.result[0].city
            }
            axios
              .post(api.postAllAreaPincodes, data2)
              .then((response) => {


                var tempArr = response.data.result;

                tempArr.sort(function (a, b) {
                  return a.pincode - b.pincode;
                });


                setPincodeList(tempArr);

              });


          });

      })
  }

  const handleChange = (e) => {


    if (e.target.name === "firstName") {
      setFirstName(e.target.value)
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value)
    } else if (e.target.name === "dob") {
      setDob(e.target.value)
    } else if (e.target.name === "landmark") {
      setLandmark(e.target.value)
    } else if (e.target.name === "address") {
      setAddress(e.target.value)
    } else if (e.target.name === "pincode") {
      setPincode(e.target.value)
    } else if (e.target.name === "city") {
      // setCity(e.target.value)
    } else if (e.target.name === "state") {
      setState(e.target.value)
    } else if (e.target.name === "email") {
      setEmail(e.target.value)
    } else if (e.target.name === "emailOtp") {
      setEmailOtp(e.target.value);
    } else {

    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    var data = {
      firstName: firstName,
      lastName: lastName,
      dob: dob,
      address: address,
      landmark: landmark,
      pincode: pincode,
      state: state,
      city: district,
      lastLogin: new Date().getTime(),
      email: email,
      phoneNumber: phoneNumber,

    }


    const isEmpty = Object.values(data).some(x => x == '');

    if (isEmpty) {

      setShowAddressError(true)

    } else {

      if (emailVerifyFlag === "true") {


        axios
          .post(api.postCustomerSignup, data)
          .then((response) => {

         
            if (response.data.status === true) {



              if (checkoutFlag === "true") {

                var cartList = [];
                cartList = JSON.parse(localStorage.getItem("cart")) === null ? [] : JSON.parse(localStorage.getItem("cart"));

                var data2 = {
                  phoneNumber: phoneNumber,
                  cart: cartList,
                  prescriptionImageArr: []
                }


                axios
                  .post(api.postAllOrder, data2)
                  .then((response) => {


                    if (response.data.status === true) {

                      localStorage.setItem("cart", JSON.stringify([]));
                      setShowOrderPlaced(true);
                    } else { }


                  })


              } else {
                setShow(true);
              }

            }

          })
          .catch((error) => console.log(error));
      }

    }



  }

  const handleSendEmailOtp = () => {


    var data = {
      phoneNumber: phoneNumber,
      email: email
    }
    axios
      .post(api.postSendEmailOtp, data)
      .then((response) => {
        setEmailVerifyFlag("pending");
      
      })
      .catch((error) => console.log(error));


  }

  const handleVerifyEmailOtp = () => {

    var data = {
      phoneNumber: phoneNumber,
      otp: emailOtp
    }
    axios
      .post(api.postVerifyEmailOtp, data)
      .then((response) => {

     
        if (response.data.status === true) {
          setEmailVerifyFlag("true");
          localStorage.setItem("email", email);
        } else {
          setEmailVerifyFlag("wrongOtp");
        }


      })
      .catch((error) => console.log(error));

  }

  const fnEmail = () => {

    if (emailVerifyFlag === "true") {
      return <> <span className="emailverified"> Email verified !!</span> </>
    } else if (emailVerifyFlag === "pending") {

      return <div><input type="number" name="emailOtp" placeholder="Enter OTP" onChange={handleChange} />
        <button className="verifyEmailbtn" onClick={handleVerifyEmailOtp}> verify OTP</button></div>
    } else if (emailVerifyFlag === "wrongOtp") {

      return <div><input type="number" name="emailOtp" placeholder="Enter OTP" onChange={handleChange} />
        <button className="verifyEmailbtn" onClick={handleSendEmailOtp}>resend Otp</button>
      </div>
    } else {
      return <button className="verifyEmailbtn" onClick={handleSendEmailOtp}> Verify Email</button>
    }

  }

  const changeState = (e) => {


    setState(e.target.value);

    var data = {
      stateName: e.target.value
    }
    axios
      .post(api.postAllDistrictName, data)
      .then((response) => {

       
        setDistrictList(response.data.result[0].districtName);

      });

  }

  const changeDistrict = (e) => {

    setDistrict(e.target.value);

    var data = {
      districtName: e.target.value
    }
    axios
      .post(api.postAllAreaPincodes, data)
      .then((response) => {


        var tempArr = response.data.result;

        tempArr.sort(function (a, b) {
          return a.pincode - b.pincode;
        });


        setPincodeList(tempArr);

      });

  }

  const changePincode = (e) => {


    setPincode(e.target.value);
  }

  return (
    <>
      <section className="cart-main checkout-main">
        <div className="container">
          <div className="col-8 justify-content-center">
            <span onClick={() => navigate("/home/dashboard")}>
              <img src={glizmedlogo} alt="img heerererer" className="customerDetailFormGlizmedLogo" />
            </span>
          </div>

          <div className="">
            <button className="backbutton" onClick={() => navigate(-1)}> Back </button>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="cart-product">

                <span className="deliveryadd">DELIVERY ADDRESS</span>
                <div className="col-lg-12 checkout-form">
                  <form onSubmit={handleSubmit}>
                    <fieldset>
                      <div className="row">
                        <div className="col-lg-6">
                          <input type="text" name="firstName" placeholder="First Name" defaultValue={firstName} onChange={handleChange} />
                        </div>
                        <div className="col-lg-6">
                          <input type="text" name="lastName" placeholder="Last Name" defaultValue={lastName} onChange={handleChange} />
                        </div>
                        <div className="col-lg-6">
                          <input type="date" name="dob" placeholder="Date of birth" defaultValue={dob} onChange={handleChange} />
                        </div>
                        <div className="col-lg-6">
                          <input type="number" name="phoneNumber" placeholder={phoneNumber} defaultValue={phoneNumber} readOnly />
                        </div>

                        <div className="col-lg-6">
                          <input type="text" name="landmark" placeholder="Landmark" defaultValue={landmark} onChange={handleChange} />
                        </div>
                        <div className="col-lg-12">
                          <input type="text" name="address" placeholder="Address" defaultValue={address} onChange={handleChange} />
                        </div>

                        <div className="col-lg-4">
                          {/* <input type="text" name="state" placeholder="State"   defaultValue={state} onChange={handleChange}/> */}

                          <select
                            className="form-control mt-2"
                            placeholder="State"
                            onChange={(e) => {
                              changeState(e);
                            }}
                            value={state}
                          >
                            <option key={"chooseState"}>{"--Choose State--"}</option>
                            {stateList.map((e, key) => {
                              return (
                                <option key={key} id={e.stateName} statename={e.stateName}>
                                  {e.stateName}
                                </option>
                              );
                            })}
                          </select>
                        </div>


                        <div className="col-lg-4">
                          {/* <input type="text" name="city" placeholder="City"  defaultValue={city} onChange={handleChange}/> */}

                          <select
                            className="form-control mt-2"
                            placeholder="State"
                            onChange={(e) => {
                              changeDistrict(e);
                            }}
                            value={district}
                          >
                            <option key={"chooseDistrict"}>{"--Choose District--"}</option>
                            {districtList.map((e, key) => {

                              return (
                                <option key={key} id={e} statename={e}>
                                  {e}
                                </option>
                              );
                            })}
                          </select>
                        </div>


                        <div className="col-lg-4">
                          {/* <input type="text" name="pincode" placeholder="Pincode"  defaultValue={pincode} onChange={handleChange}/> */}


                          <select
                            className="form-control mt-2"
                            placeholder="State"
                            onChange={(e) => {
                              changePincode(e);
                            }}
                            value={pincode}
                          >
                            <option key={"choosePincode"}>{"--Choose Pincodee--"}</option>
                            {pincodeList.map((e, key) => {

                              return (
                                <option key={key} id={e} pincode={e.pincode}>
                                  {e.pincode}, {e.taluk} , {e.officeName}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="col-lg-6 mt-5">
                          {emailVerifyFlag === "true" ? <><input type="email" name="email" placeholder={email} readOnly /> </> :
                            <><input type="email" name="email" placeholder="Email Id" defaultValue={email} onChange={handleChange} /> </>}
                        </div>
                        <div className="col-lg-6 mt-5">
                          {fnEmail()}

                        </div>

                        <div className="col-lg-12 text-right">
                          <input type="submit" name="" value={checkoutFlag === "true" ? "Confirm" : "Submit"} />
                        </div>

                        {emailVerifyFlag === "false" ? <span> Please verify your email before you submit the form</span> : <></>}
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProfileUpdatedModal
        show={show}
        setShow={setShow}
        navigate={navigate}
      />

      <OrderPlacedModal
        show={showOrderPlaced}
        setShow={setShowOrderPlaced}
        navigate={navigate}
      />

      <AddressErrorModal
        show={showAddressError}
        setShow={setShowAddressError} />

    </>

  );
}


export default CustomerDetailForm;