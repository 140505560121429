import "../App.css";
import { useState, useCallback } from "react";
import React from "react";
import {api} from "../utils/ApiUrl";

import axios from "axios";


function Register() {
  const [medicineName, setMedicineName] = useState();

  const [price, setPrice] = useState();
  const [brandName, setBrandName] = useState();
  const [amountInStock, setAmountInStock] = useState();
  const [category, setCategory] = useState();
  const [fileOne, setFileOne] = useState();
  function onChangeHandle(event) {
    if (event.target.name === "medicineName") {
      setMedicineName(event.target.value);
    } else if (event.target.name === "price") {
      setPrice(event.target.value);
    } else if (event.target.name === "brandName") {
      setBrandName(event.target.value);
    } else if (event.target.name === "category") {
      setCategory(event.target.value);
    } else if (event.target.name === "amountInStock") {
      setAmountInStock(event.target.value);
    } else {
    }
  }

  function submitform() {
    const data = new FormData();
    data.append("medicineName", medicineName);
    data.append("price", price);
    data.append("brandName", brandName);
    data.append("category", category);
    data.append("amountInStock", amountInStock);
    // data.append("folderName", new Date().getTime());

    for (let i = 0; i < fileOne.length; i++) {
      data.append("fileOne", fileOne[i]);
    }

    axios
      .post(api.getAllMedicine, data)
      .then((response) => {})
      .catch((error) => console.log(error));
  }

  return (
    <div className="container-fluid ">
      Add Medicine FORM
      <form encType="multipart/form-data">
        <label>
          Name of medicine:
          <input type="text" name="medicineName" onChange={onChangeHandle} />
        </label>
        <br />
        <label>
          Images of medicine (max 4 allowed):
          <input
            type="file"
            name="fileOne"
            multiple
            onChange={(e) => {
              setFileOne(e.target.files);
            }}
          />
        </label>
        <br />
        <label>
          Price per medicine:
          <input type="text" name="price" onChange={onChangeHandle} />
        </label>
        <br />
        <label>
          Brand Name:
          <input type="text" name="brandName" onChange={onChangeHandle} />
        </label>
        <br />
        <label>
          Category :
          <input type="text" name="category" onChange={onChangeHandle} />
        </label>
        <br />
        <label>
          Amount left in stock:
          <input type="text" name="amountInStock" onChange={onChangeHandle} />
        </label>
        <br />

        <input type="button" value="Submit" onClick={submitform} />
      </form>
    </div>
  );
}
export default Register;
