import "../../App.css"
import glizmedlogo from "../../images/glizmed-logo.svg";
import React, { useState,useEffect } from "react";
import OTPInput  from "otp-input-react";

function VerifyOTP(props) {

    const [OTP, setOTP]= useState();
    const [showSendCodeAgain, setShowSendCodeAgain]= useState(false);
    const [seconds, setSeconds] = React.useState(30);

    const handleClickSendCodeAgain = ()=> {
       
       props.sendOTP();
      
       setShowSendCodeAgain(false);
       }

   const handleChange = (otp)=> {
  
    setOTP(otp)
    props.setOtp(otp);
   }


   useEffect(() => {
    
    var timeOutVar;
    if (seconds > 0) {
      
        setShowSendCodeAgain(false);
        timeOutVar = setTimeout(() => setSeconds(seconds - 1), 1000);
      
    } else {
      
        setShowSendCodeAgain(true);
        setSeconds("");
      
    }

    return () => {
    
      clearTimeout(timeOutVar);
    };
  }, [seconds]);

  return <> 
  <section className="signin-main">
  <div id="recaptcha-container"></div>
		<div className="container">
			<div className="row">
				<div className="col-lg-12 text-center justify-content-center">
					<div className="account-inner">
						<img src={glizmedlogo} className="brand-logo" alt="" loading="lazy" />
                        <div className="login-form-head pb-1 mb-3 bb-so-1 bc d-flex align-items-baseline justify-content-between">
                            <span className="login-form-title">Sign in</span> <span className="pull-right"> <a className="register-link" href="#" title="Register">Create an Account</a> </span>
                        </div>
                      
                        <OTPInput value={OTP} onChange={handleChange} autoFocus OTPLength={6} otpType="number" disabled={false}   />
                        
                            <button onClick={props.verifyOTP} className="btn btn-primary btn-block w-100 mt-1"><span>Verify OTP</span></button>
                            <span class="result"></span>
                          
                       
                        <div className="login-form-bottom">
                  {seconds !=="" ?<h4> Didn't receive the code? RESEND OTP in {seconds} seconds</h4> : <></>}  
                        {showSendCodeAgain ? 
                         <button onClick={handleClickSendCodeAgain} className="btn btn-primary btn-block w-100 mt-1"><span>Resend OTP</span></button>:<></> }   <br />
						    {/* <a href="sign-in.html" className="mt-2 lostpass-link d-inline-block" title="Change Phone Number">Change Phone Number</a> */}
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</section>
  </>;
}

export default VerifyOTP;
