import "../../App.css"

import glizmedlogo from "../../images/glizmed-logo.svg";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

   
function SendOTP(props) {


  let navigate = useNavigate();
   
  return <> 
  <section className="signin-main">
		<div className="container">
			<div className="row">
      <div id="recaptcha-container"></div>
				<div className="col-lg-12 text-center justify-content-center">
					<div className="account-inner">
                        <img src={glizmedlogo} className="brand-logo" alt="" loading="lazy" onClick={()=>{navigate("/home/dashboard")}}/>
                        <div className="login-form-head pb-1 mb-3 bb-so-1 bc d-flex align-items-baseline justify-content-between">
                            <span className="login-form-title">Sign in</span> 
                            {/* <span className="pull-right"> <a className="register-link" href="#" title="Register">Create an Account</a> </span> */}
                        </div>
                        {/* <form className="opal-login-form-ajax" data-toggle="validator" > */}
                            <p>
                                <label>Phone No <span className="required">*</span></label>
                                <input className="input__prefix" defaultValue="+91" type="text" name="phonenumber-prefix"  tabIndex="-1" />
                                <input className="input__phoneno" name="phoneno" type="number" required="" placeholder="Phone No" onChange={(e)=> {props.setPhoneNumber(e)}}/>
                            </p>
                            <button onClick={props.sendOTP} className="btn btn-primary btn-block w-100 mt-1"><span>Get OTP</span></button>

                        {/* </form>  */}
                    </div>
				</div>
			</div>
		</div>
	</section>
  </>;

}

export default SendOTP;
