import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthDataContext } from "./AuthDataProvider";
import { api } from "../utils/ApiUrl";
import axios from "axios";
import UploadPresErrorModal from "../utils/UploadPresErrorModal";
import UploadPresModal from "../utils/UploadPresModal";
import whatsappImg from "../images/whatsapp.png";
import callUsImg from "../images/Headphones.png";
function OrderMedicine() {

  let navigate = useNavigate();
  const { name } = useAuthDataContext();
  const [uploadPresMessage, setUploadPresMessage] = useState("");
  const [showUploadPresErrorModal, setShowUploadPresErrorModal] = useState(false);
  const [showUploadPresModal, setShowUploadPresModal] = useState(false);
  const [navigationPath, setNavigationPath] = useState("");


  const handleUploadPrescription = () => {


    var checkLoginStatus = localStorage.getItem("loginFlag");
    if (checkLoginStatus === "true") {


      //getVerificationStatus of email id of this user by phonenumber
      axios
        .get(api.getVerificationStatus + localStorage.getItem("phoneNumber"))
        .then((response) => {


          if (response.data.result[0].emailStatus === "true") {

            // get customer by phonenumber
            axios
              .get(api.getCustomerByPhoneNumber + localStorage.getItem("phoneNumber"))
              .then((responseCus) => {

                if (responseCus.data.result[0].address.length <= 5 || responseCus.data.result[0].landmark < 4) {

                  setUploadPresMessage("Please fill the Your complete address first");
                  setNavigationPath("/customerDetail")
                  setShowUploadPresErrorModal(true);

                } else {


                  // navigate("/uploadPrescription");
                  setShowUploadPresModal(true);
                }

              })

          } else {
            setUploadPresMessage("Please verify your email first !");
            setNavigationPath("/customerDetail");

            setShowUploadPresErrorModal(true);
          }
        })
        .catch((error) => {

          console.log("Axios catchhhhh of upload prescription click");
          setUploadPresMessage("Please fill the Your complete address first");

          setNavigationPath("/customerDetail");

          setShowUploadPresErrorModal(true);
          console.log(error)

        });

    }
    else {
      setUploadPresMessage("Lets Login first");
      setNavigationPath("/login");

      setShowUploadPresErrorModal(true);
    }

  }




  return (<>


    <div className="container">



      <div className="row justify-content-around">
        <div className="col-lg-3 uploadPrescriptionDiv" onClick={handleUploadPrescription} >
          {/*                 
        <label for="files" class="btn">Upload prescription</label>
        <input id="files" style={{visibility:"hidden"}} type="file" />  */}

          <span> Upload prescription</span>

        </div>

        <div className="col-lg-3 whatsappDiv">
          <a href="https://wa.me/9930665480" target="_blank" style={{ color: "white" }}>
            <div className="row" style={{ height: "100%" }}>
              <div className="col-lg-6" >  <i className="fa fa-whatsapp" aria-hidden="true"></i> <br /><br />
                Whatsapp </div>

              <div className="col-lg-6" >
                <img src={whatsappImg} style={{ position: "absolute", right: "10px", width: "70px", height: "70px", bottom: "0", right: "0" }} />
              </div>

            </div>
          </a>
        </div>


        <div className="col-lg-3 callUsDiv">
          <div className="row" style={{ height: "100%" }}>
            <div className="col-lg-6" >  <i class="fa fa-phone" aria-hidden="true"></i> <br /><br />
              Call Us </div>

            <div className="col-lg-6" >
              <img src={callUsImg} style={{ position: "absolute", right: "10px", width: "80px", height: "80px", bottom: "0", right: "0" }} />
            </div>

          </div>

        </div>

      </div>




    </div>


    <UploadPresErrorModal
      show={showUploadPresErrorModal}
      setShow={setShowUploadPresErrorModal}
      navigate={navigate}
      uploadPresMessage={uploadPresMessage}
      navigationPath={navigationPath}
    />

    <UploadPresModal
      show={showUploadPresModal}
      setShow={setShowUploadPresModal}
    />
  </>
  )

}

export default OrderMedicine;
