import "../App.css";

import React from "react";
import { useState ,useEffect} from "react";

function MyOrders(props) {


	// const [orderList, setOrderList] = useState([]);
    // const [prescriptionList, setPrescriptionList] = useState([]);
  
   
    // useEffect(() => {

    //     const config = {
    //         headers: { Authorization: `Bearer ${iDtoken}` }
    //     };

      
    //         axios
    //             .get(api.baseUrl + "api/get-all-orders-by-phoneNumber/+919930665480", config)
    //             .then((response) => {
    
    //                 setOrderList(response.data.result[0].orderArr);
    //                 setPrescriptionList(response.data.result[1].prescriptionArr);
                
    
    //             })

    // }, []);




     return (
     
	
<section class="search-result order-history">
<div class="">
	{/* <!-- <div class="row">
		<div class="col-lg-12">
			<div class="breadcrum-navigation">
				<ul>
					<li><a href="#">Home</a></li>
					<li class="active"><a href="#">Blog</a></li>
				</ul>
			</div>
		</div>
	</div> --> */}
	<h1>Order History</h1>
	<div class="row">
		<div class="col-lg-9">
			<div class="row">
			
				<div class="col-lg-12 col-md-12">
					<div class="product-details">
						<div class="product-itemdetails row ng-star-inserted">
							<div class="leftside-icons col-2 p-0">
								<a class="product-item-photo" title="">
									<img class="pro-img" src="images/product-13.jpg" />
								</a>
							</div>
							<div class="rightside-details col pr-0">
								<div class="row m-0">
									
									<div class="col-5 p-0">
										<div class="product-item-name col-12 pl-0">
											<a href="#">Organika Salmon Collagen</a>
										</div>
										<div class="catag-name col-12 pl-0">
											<a href="#" rel="tag">Diabetes</a>, <a href="#" rel="tag">Fitness</a>
										</div>
										<div class="item-prices col-12 p-0">
											<div class="discount-val">
												<span class="best-price">Purchase Price*</span>
												<span id="row_itmdiscprice_930167">$74.00</span>
											</div>
										</div>
									</div>

									<div class="col-7">
										{/* <!-- <div class="order-address">
											<h3>Yakub Shaikh</h3>
											<p>65, Aditya Industrial Estate, Malad Mindspace Near Evershine mall, Malad (w), Mumbai - 400064, Maharashtra</p>
											<span><strong>Phone Number</strong> 8169005172</span>
										</div> --> */}
										<div class="order-history">
											<p class="order-history-status status-approved">Order Status: <span class="blinking"> Approved </span></p>
											<div class="row">
												<div class="col-6">
													<p class="order-date">Order Date: <span> 30-08-2022</span> </p>
												</div>
												<div class="col-6">
													<p class="delivery-date">Delivery Date: <span> 30-08-2022</span> </p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="product-details">
						<div class="product-itemdetails row ng-star-inserted">
							<div class="leftside-icons col-2 p-0">
								<a class="product-item-photo" title="">
									<img class="pro-img" src="images/product-13.jpg" />
								</a>
							</div>
							<div class="rightside-details col pr-0">
								<div class="row m-0">
									<div class="col-5 p-0">
										<div class="product-item-name col-12 pl-0">
											<a href="#">Organika Salmon Collagen</a>
										</div>
										<div class="catag-name col-12 pl-0">
											<a href="#" rel="tag">Diabetes</a>, <a href="#" rel="tag">Fitness</a>
										</div>
										<div class="item-prices col-12 p-0">
											<div class="discount-val">
												<span class="best-price">Purchase Price*</span>
												<span id="row_itmdiscprice_930167">$74.00</span>
											</div>
										</div>
									</div>

									<div class="col-7">
										<div class="order-history">
											<p class="order-history-status status-rejected">Order Status: <span class="blinking"> Rejected </span></p>
											<div class="row">
												<div class="col-6">
													<p class="order-date">Order Date: <span> 30-08-2022</span> </p>
												</div>
												<div class="col-6">
													<p class="delivery-date">Delivery Date: <span> 30-08-2022</span> </p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="product-details">
						<div class="product-itemdetails row ng-star-inserted">
							<div class="leftside-icons col-2 p-0">
								<a class="product-item-photo" title="">
									<img class="pro-img" src="images/product-13.jpg" />
								</a>
							</div>
							<div class="rightside-details col pr-0">
								<div class="row m-0">
									<div class="col-5 p-0">
										<div class="product-item-name col-12 pl-0">
											<a href="#">Organika Salmon Collagen</a>
										</div>
										<div class="catag-name col-12 pl-0">
											<a href="#" rel="tag">Diabetes</a>, <a href="#" rel="tag">Fitness</a>
										</div>
										<div class="item-prices col-12 p-0">
											<div class="discount-val">
												<span class="best-price">Purchase Price*</span>
												<span id="row_itmdiscprice_930167">$74.00</span>
											</div>
										</div>
									</div>

									<div class="col-7">
										<div class="order-history">
											<p class="order-history-status status-pending">Order Status: <span class="blinking"> Pending </span></p>
											<div class="row">
												<div class="col-6">
													<p class="order-date">Order Date: <span> 30-08-2022</span> </p>
												</div>
												<div class="col-6">
													<p class="delivery-date">Delivery Date: <span> 30-08-2022</span> </p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="product-details">
						<div class="product-itemdetails row ng-star-inserted">
							<div class="leftside-icons col-2 p-0">
								<a class="product-item-photo" title="">
									<img class="pro-img" src="images/product-13.jpg" />
								</a>
							</div>
							<div class="rightside-details col pr-0">
								<div class="row m-0">
									<div class="col-5 p-0">
										<div class="product-item-name col-12 pl-0">
											<a href="#">Organika Salmon Collagen</a>
										</div>
										<div class="catag-name col-12 pl-0">
											<a href="#" rel="tag">Diabetes</a>, <a href="#" rel="tag">Fitness</a>
										</div>
										<div class="item-prices col-12 p-0">
											<div class="discount-val">
												<span class="best-price">Purchase Price*</span>
												<span id="row_itmdiscprice_930167">$74.00</span>
											</div>
										</div>
									</div>

									<div class="col-7">
										<div class="order-history">
											<p class="order-history-status">Order Status: <span class="blinking"> On Process </span></p>
											<div class="row">
												<div class="col-6">
													<p class="order-date">Order Date: <span> 30-08-2022</span> </p>
												</div>
												<div class="col-6">
													<p class="delivery-date">Delivery Date: <span> 30-08-2022</span> </p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="product-details">
						<div class="product-itemdetails row ng-star-inserted">
							<div class="leftside-icons col-2 p-0">
								<a class="product-item-photo" title="">
									<img class="pro-img" src="images/product-13.jpg" />
								</a>
							</div>
							<div class="rightside-details col pr-0">
								<div class="row m-0">
									<div class="col-5 p-0">
										<div class="product-item-name col-12 pl-0">
											<a href="#">Organika Salmon Collagen</a>
										</div>
										<div class="catag-name col-12 pl-0">
											<a href="#" rel="tag">Diabetes</a>, <a href="#" rel="tag">Fitness</a>
										</div>
										<div class="item-prices col-12 p-0">
											<div class="discount-val">
												<span class="best-price">Purchase Price*</span>
												<span id="row_itmdiscprice_930167">$74.00</span>
											</div>
										</div>
									</div>

									<div class="col-7">
										<div class="order-history">
											<p class="order-history-status">Order Status: <span class="blinking"> On Process </span></p>
											<div class="row">
												<div class="col-6">
													<p class="order-date">Order Date: <span> 30-08-2022</span> </p>
												</div>
												<div class="col-6">
													<p class="delivery-date">Delivery Date: <span> 30-08-2022</span> </p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
					
			</div>

		</div>
	

		<div class="col-lg-3 sticky-top">
			<div class="row">
				<div class="col-lg-12 col-md-6 col-12">
					<div class="sidebar_widget">
						<div class="search-box">
							<form class="form-inline my-2 my-lg-0">
								<input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
								<button class="btn btn-outline-success my-2 my-sm-0" type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
							</form>
						</div>
					</div>
				</div>

				<div class="col-lg-12 col-md-6 col-12">
					<div class="sidebar_widget">
						<h3 class="widget-title">Order Time</h3>
						<ul class="filters__list">
							<li>
								<input id="f1" type="checkbox" />
								<label for="f1">Last 30 Days</label>
							</li>
							<li>
								<input id="f2" type="checkbox" />
								<label for="f2">2022</label>
							</li>
							<li>
								<input id="f3" type="checkbox" />
								<label for="f3">2021</label>
							</li>
							<li>
								<input id="f4" type="checkbox" />
								<label for="f4">2020</label>
							</li>
							<li>
								<input id="f5" type="checkbox" />
								<label for="f5">Older</label>
							</li>
						</ul>

					</div>
				</div>

				{/* <!-- <div class="col-lg-12 col-md-6 col-12">
					<div class="sidebar_widget">
						<h3 class="widget-title">Latest Posts</h3>
						<div class="sidebar-blog">
							<img src="images/blog-3.jpg"/>
							<div class="blog-deatils-sidebar">
								<span class="posted-on">
									Posted on:
									<a href="#" rel="bookmark">
										<time class="entry-date published" datetime="2020-10-14T09:30:46+00:00">14-Oct-2020</time>
									</a>
								</span>
								<h4 class="entry-title"><a href="#">Thyroid in Kids: Everything You Need to Know</a></h4>
							</div>
						</div>
						<div class="sidebar-blog">
							<img src="images/blog-3.jpg"/>
							<div class="blog-deatils-sidebar">
								<span class="posted-on">
									Posted on:
									<a href="#" rel="bookmark">
										<time class="entry-date published" datetime="2020-10-14T09:30:46+00:00">14-Oct-2020</time>
									</a>
								</span>
								<h4 class="entry-title"><a href="#">Thyroid in Kids: Everything You Need to Know</a></h4>
							</div>
						</div>
						<div class="sidebar-blog">
							<img src="images/blog-3.jpg"/>
							<div class="blog-deatils-sidebar">
								<span class="posted-on">
									Posted on:
									<a href="#" rel="bookmark">
										<time class="entry-date published" datetime="2020-10-14T09:30:46+00:00">14-Oct-2020</time>
									</a>
								</span>
								<h4 class="entry-title"><a href="#">Thyroid in Kids: Everything You Need to Know</a></h4>
							</div>
						</div>
						
					</div>
				</div> */}

				{/* <div class="col-lg-12 col-md-6 col-12">
					<div class="sidebar_widget">
						<h3 class="widget-title">Product Tag</h3>
						<ul class="product-tag">
							<li><a href="#">Pain Killer</a></li>
							<li><a href="#">Covid Essentials</a></li>
							<li><a href="#">Devices</a></li>
							<li><a href="#">Diabetes</a></li>
						</ul>
					</div>
				</div> --> */}
			</div>
		</div>
	</div>
</div>




</section>

        

 
  );
}

export default MyOrders;
