import "../App.css";

import React from "react";
import { useState ,useEffect} from "react";

function Test(props) {

    const [name, setName] = useState("");
    useEffect(() => {
  
         
           
       }, []);
  return <div className="">
Hello Test!

  </div>;
}

export default Test;
  