const baseUrl="http://node-express-atlas-env.eba-ihmgrdcw.ap-south-1.elasticbeanstalk.com/"
export const api={
   abc: "sadhsd",
   baseUrl:"http://node-express-atlas-env.eba-ihmgrdcw.ap-south-1.elasticbeanstalk.com/",

   getAllBrandName:baseUrl + "api/allbrandName",

   getAllMedicine :baseUrl + "api/medicine",
   getAllCategory:baseUrl + "api/allCategories",
   getVerificationStatus:baseUrl + "api/getVerificationStatus/",
   getCustomerByPhoneNumber:baseUrl + "api/getCustomerByphoneNumber/",
   getCustomerCart:baseUrl + "api/customer/cart/",
   postCustomerCart:baseUrl + "api/customer/cart",
   getAllStates:baseUrl + "api/getStates",
   postAllDistrictName:baseUrl + "api/getDistrictName",
   postAllAreaPincodes:baseUrl + "api/getAreaPincodes",
   postCustomerSignup:baseUrl + "api/customer/SignUp",
   postAllOrder:baseUrl + "api/order",
   postSendEmailOtp:baseUrl + "api/sendEmailOtp",
   postVerifyEmailOtp:baseUrl + "api/verifyEmailOtp",
   postSearchMedicineById:baseUrl + "api/search-medicine-by-id",
   postAllMedicine:baseUrl + "api/medicine",
   postSearchMedicine:baseUrl + "api/search-medicine",
   postCustomerPrescriptionUpload:baseUrl + "api/customerPrescriptionUpload",
  
}