import "../App.css";
import { Outlet, Link, useNavigate } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import Footer from "./Footer.js";
import glizmedlogo from "../images/glizmed-logo.svg";
import { useAuthDataContext } from "./AuthDataProvider";
import { getIdToken, onAuthStateChanged ,getIdTokenResult ,signOut} from "firebase/auth";
import {authentication} from "../services/firebase";

 
// signOut(authentication)
// .then(r => console.log(r)) 
// .catch();
// onAuthStateChanged(authentication, async (user) => {
//   if (user) {
//     const token = await getIdToken(user);
//     console.log(token + " tokennnn");

//     const tokenRes = await getIdTokenResult(user);
//     console.log(JSON.stringify(tokenRes) + " Resulttt");
//   }
// });

const PrivateRoute = () => {
  let navigate = useNavigate();

  const { searchParam, setSearchParam , setCheckoutFlag,showAddedToCart } = useAuthDataContext();
  const [scroll,setScroll] = useState(false);

  const [localLoginFlag, setLocalLoginFlag]= useState("");
  let [cartCount , setCartCount] = useState(0);


  var searchTemp;

  const handleSearch = (e) => {
   
    searchTemp = e.target.value;
  };

  const handleSearchBtn = () => {
    setSearchParam(searchTemp);
    navigate("/home/search");
  };

  const   handleHealthProducts = () => {
   
    navigate("/home/healthProducts");
  };

  const handleOrderMedicine = ()=> {
    navigate("/home/orderMedicine");
  }

  const  handleLabTests = () => {
   
    navigate("/home/labTests");
  };

  
useEffect(() => {
  
  window.addEventListener("scroll", ()=> {

if(window.pageYOffset >50) {
  setScroll(true);
}else {
  setScroll(false);
}
   
  })

  var tempFlag =localStorage.getItem("loginFlag");

  setLocalLoginFlag(tempFlag); 

  var cartList =[];
  cartList =JSON.parse(localStorage.getItem("cart")) === null ? []:JSON.parse(localStorage.getItem("cart"));
  setCartCount(cartList.length);
 }, [showAddedToCart]);
 
const handleSignUp = ()=> {
 
  setCheckoutFlag("");
      navigate("/login");
    
}

const handleMyProfile = () => {

  setCheckoutFlag("");
  navigate("/customerDetail");
}

const handleUploadPrescription = () => {
  navigate("/uploadPrescription");
}


  return (
    <>
      <header>
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <p>
                  Free Shipping for all Order
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <ul>
                  <li>
                    <a href="#" target="_blank">
                      <span className="elementor-screen-only">Facebook</span>{" "}
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <span className="elementor-screen-only">Twitter</span>{" "}
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <span className="elementor-screen-only">Instagram</span>{" "}
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <span className="elementor-screen-only">Google-plus</span>{" "}
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={scroll==true ? "main-header sticky":"main-header" } >
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-8">
                <span onClick={()=> navigate("/home/dashboard")}>
                  <img src={glizmedlogo} alt="img heerererer" />
                </span>
              </div>
              <div className="col-lg-7 col-md-9 col-4">
                <div className="search-box">
                  {/* <form className="form-inline my-2 my-lg-0"> */}
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    name="search"
                    onChange={handleSearch}
                    autoComplete="off"
                  />
                  <button
                    className="btn btn-outline-success my-2 my-sm-0"
                    type="submit"
                    onClick={handleSearchBtn}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                  {/* </form> */}
                </div>

                <nav className="navbar navbar-expand-lg ">
                  <button
                    className="navbar-toggler"
                    id="navToggle"
                    type="button"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="navbar-collapse offcanvas-collapse"
                    id="navbarNavDropdown"
                  >
                    <button
                      className="navbar-toggler"
                      id="navToggle"
                      type="button"
                    >
                      <span className="navbar-toggler-icon close"></span>
                    </button>
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item" onClick={handleOrderMedicine} >
                      <a className="nav-link" href="#">
                        Pharmacy
                        </a>
                       

                      </li>
                      {/* <li onClick={handleHealthProducts} className="nav-item">
                    
                         HealthProducts
                     
                      </li> */}
                      <li  onClick={handleHealthProducts}  className="nav-item">
                        <a className="nav-link" href="#">
                     HealthProducts
                        </a>
                       

                      </li>

                    
                      <li  onClick={handleLabTests}  className="nav-item">
                        <a className="nav-link" href="#">
                    Lab Tests

                        </a>
                       

                      </li>

                      <li    className="nav-item">
                        <a className="nav-link" href="#">
                      Surgeries
                        </a>
                       
         </li>
                      <li  className="nav-item">
                        <a className="nav-link" href="#">
                    Consult Doctor
                        </a>
                      </li>

                      {/* <li className="nav-item dropdown">
=======
                      <li className="nav-item dropdown">
>>>>>>> 1386201a5e81bd75733b601737ad86653d65f15c
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Dropdown
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            Action
                          </a>
                          <a className="dropdown-item" href="#">
                            Another action
                          </a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="col-lg-3 text-right">
                <div className="account">
                  <div className="site-header-account">
                    <a className="opal-header-account header-button">
                      <div className="text-account d-block">

                     {localLoginFlag ==="true" ?
                        
                        <span data-toggle="modal" data-target="#basicModal" onClick={handleMyProfile}>
                        My Account
                      </span>
                            :
              <span data-toggle="modal" data-target="#basicModal" onClick={handleSignUp}>
              Sign in / Sign up
              </span>
               
               }
                      </div>
                    </a>
                  </div>
                </div>

                <div className="product-head">
                  <div className="site-header-wishlist">
                    <div className="site-header-wishlist-config" onClick={()=>{ navigate("/home/myOrders")}} >
                      <a
                        className="opal-header-wishlist header-button"
                      
                      >
                        <i className="fa-regular fa-heart" ></i>
                      </a>
                    </div>
                  </div>
                  <div className="cart-woocommerce" onClick={()=>{ navigate("/home/cart")}}>
                    <div className="site-header-cart menu d-flex">
                      <a
                        data-toggle="toggle"
                        className="cart-contents header-button"
                       
                      >
                        <i className="fa-light fa-cart-plus"></i>{" "}
                        <span className="title"></span>{" "}
                        <span className="count d-inline-block text-center">
                         {cartCount}
                        </span>{" "}
                        <span className="amount">$0.00</span>
                      </a>
                      <ul className="shopping_cart">
                        <li>
                          <div className="widget woocommerce widget_shopping_cart">
                            <div className="widget_shopping_cart_content"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="outletClass">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default PrivateRoute;
