import React from "react";
import Modal from "react-bootstrap/Modal";


export default function AddressErrorModal(props) {
  const handleClose = () => props.setShow(false);
  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        style={{
          top: "20%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please Make Sure all the fields are filled Properly and None of them are empty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentProfileUpdatedDiv">
         
            {/* <img src={tickMark}  alt="document" className="glizmedLogoProfileUpdateModal"/ > <br /><br /> */}
            <button className="updatedProfileBtn" onClick={()=> {props.setShow(false)}}> OK </button>
         
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
