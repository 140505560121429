import { useEffect } from "react";

import React, { useState } from "react";
 import {authentication} from "../../services/firebase";
 import { RecaptchaVerifier ,signInWithPhoneNumber } from "firebase/auth";
 import SendOTP from "./SendOTP";
 import VerifyOTP from "./VerifyOTP"
 import Success from "./Success";
 import { useNavigate } from "react-router-dom";
 import axios from "axios";
 import {fnGetCurrentTime , fnGetCurrentDate} from "../../utils/CalculateTime";
 import {api} from "../../utils/ApiUrl";

function Login() {
  let navigate = useNavigate();
    const [phoneNumber,setPhoneNumber ] = useState();
    const [otp,setOtp ] = useState();
    const [componentShown , setComponentShown] =useState("sendOtp");


    useEffect(() => {

 var checkLoginStatus= localStorage.getItem("loginFlag");
   if(checkLoginStatus === "true") {
navigate("/home/dashboard");
   }  
     
     }, []);

const fnSetPhoneNumberHandle = (e) => {

setPhoneNumber("+91"+e.target.value);
}

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
          
            }
          }, authentication);
    }
    const sendOTP = () => {
        generateRecaptcha(); 
      
        let appVerifier =window.recaptchaVerifier;
        signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
    .then((confirmationResult) => {

      
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      setComponentShown("verifyOtp");
      
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      // ...
    });
    }

    const verifyOTP = ()=> {
        
       
        let confirmationResult= window.confirmationResult;
        confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            const user = result.user;
  

            localStorage.setItem("uid", user.uid);
            localStorage.setItem("accessToken", user.stsTokenManager.refreshToken);
            localStorage.setItem("expirationTime", user.stsTokenManager.expirationTime);
            localStorage.setItem("lastLoginAt", user.lastLoginAt);
            localStorage.setItem("createdAt", user.createdAt);
            localStorage.setItem("loginFlag","true");
            localStorage.setItem("phoneNumber",phoneNumber);


            axios
            .get(api.baseUrl+`api/getCustomerByphoneNumber/${phoneNumber}`)
            .then((response) => {
    
    if(response.data.status === true) {
    

      var data= {firstName:response.data.result[0].firstName,
      lastName: response.data.result[0].lastName,
      dob: response.data.result[0].dob,
      address:response.data.result[0].address,
      landmark: response.data.result[0].landmark,
      pincode: response.data.result[0].pincode,
      state: response.data.result[0].state,
      city:response.data.result[0].city,
      lastLogin: response.data.result[0].lastLogin,
      email: response.data.result[0].email,
      phoneNumber: phoneNumber,
      loginDetails:[{loginDate:fnGetCurrentDate,
         loginTime:fnGetCurrentTime,
        accessToken: user.stsTokenManager.refreshToken,
        expirationTime: user.stsTokenManager.expirationTime,
       
       }] }
      
      axios
      .post(api.baseUrl+"api/customer/SignUp",data)
      .then((response) => {
        //if(response.data.status === true) {
          setComponentShown("success");
        //}

      })
     
    
    }  else {
    
       var data= {firstName:"",
            lastName: "",
            dob: "",
            address:"",
            landmark: "",
            pincode: "",
            state: "",
            city: "",
            lastLogin: "",
            email: "",
            phoneNumber: phoneNumber,
            loginDetails:[{loginDate:fnGetCurrentDate,
               loginTime:fnGetCurrentTime,
              accessToken: user.stsTokenManager.refreshToken,
              expirationTime: user.stsTokenManager.expirationTime,
             
             }] }
            
            axios
            .post(api.baseUrl+"api/customer/SignUp",data)
            .then((response) => {
              if(response.data.status === true) {
                setComponentShown("success");
              }
    
            })
    }
     })
    
            // setComponentShown("success");
       
            }).catch((error) => {
           
            console.log("FAILED TO VERIFY "+ error);
          });

    

    
    


    }

if(componentShown === "sendOtp") {
  return (
    <>
    <SendOTP setPhoneNumber={(e)=> {fnSetPhoneNumberHandle(e)}} sendOTP={sendOTP} />
    </>
  );
} else if(componentShown === "verifyOtp") {
  return (
    <>

<VerifyOTP setOtp={setOtp} verifyOTP={verifyOTP} sendOTP={sendOTP}/>
    </>
   );
} else {
  return (
    <>
<Success />
</>
  );
}
  
}
 
export default Login;