import React from "react";
import Modal from "react-bootstrap/Modal";
import logo from "../images/glizmed-logo.svg";

//PROFILE UPDATED MODAL
export default function UploadPresErrorModal(props) {
  const handleClose = () => props.setShow(false);
  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        style={{
          top: "20%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.uploadPresMessage}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentProfileUpdatedDiv">
         
            <img src={logo}  alt="document" className="glizmedLogoProfileUpdateModal"/ > <br /><br />
            <button className="updatedProfileBtn" onClick={()=> {props.navigate(props.navigationPath)}}> OK </button>
         
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
