import "../App.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthDataContext } from "./AuthDataProvider";
import { api } from "../utils/ApiUrl";
function Cart(props) {
	let navigate = useNavigate();


	const { setCheckoutFlag, setShowAddedToCart } = useAuthDataContext();
	const [carList, setCartList] = useState([]);


	useEffect(() => {


		var checkLoginStatus = localStorage.getItem("loginFlag");
		if (checkLoginStatus === "true") {

			axios
				.get(api.getCustomerCart + localStorage.getItem("phoneNumber"))
				.then((response) => {

					if (response.data.result.length > 0) {

						var cartList = [];
						cartList = JSON.parse(localStorage.getItem("cart")) === null ? [] : JSON.parse(localStorage.getItem("cart"));
						if (cartList.length > 0) {


							// both localstorage and database has values so remove unique values from both of them

							response.data.result[0].cart.forEach((value) => {
								var boolcheck = false;
								cartList.forEach(value2 => {

									if (value.name == value2.name) {
										boolcheck = true;
									}

								})

								if (boolcheck == false) {
									cartList.push(value);
								}

							})

							setCartList(cartList);
						}
						else {

							//user is logged in but cart in local storage is empty so setting what is in database
							setCartList(response.data.result[0].cart);
						}

					} else {

						//Response from database is 0 so take from local storage

						var cartList = [];
						cartList = JSON.parse(localStorage.getItem("cart")) === null ? [] : JSON.parse(localStorage.getItem("cart"));
						setCartList(cartList);
					}

				})
				.catch((error) => console.log(error));

		} else {

			// User is not logged in so take from local storage

			var cartList = [];
			cartList = JSON.parse(localStorage.getItem("cart")) === null ? [] : JSON.parse(localStorage.getItem("cart"));

			setCartList(cartList);

		}



	}, []);

	// handle input change
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...carList];
		list[index][name] = value;
		setCartList(list);

		localStorage.setItem("cart", JSON.stringify(list));

		var checkLoginStatus = localStorage.getItem("loginFlag");
		if (checkLoginStatus === "true") {

			var data = {
				phoneNumber: localStorage.getItem("phoneNumber"),
				cart: list
			}


			axios
				.post(api.postCustomerCart, data)
				.then((response) =>{})
				.catch((error) => console.log(error));

		}


	};

	// handle click event of the Remove button
	const handleRemoveClick = index => {
		const list = [...carList];
		list.splice(index, 1);
		setCartList(list);
		localStorage.setItem("cart", JSON.stringify(list));
		var checkLoginStatus = localStorage.getItem("loginFlag");
		if (checkLoginStatus === "true") {

			var data = {
				phoneNumber: localStorage.getItem("phoneNumber"),
				cart: list
			}

			console.log(JSON.stringify(list) + " listtttt");
			axios
				.post(api.postCustomerCart, data)
				.then((response) => {})
				.catch((error) => console.log(error));

		}

		setShowAddedToCart(true);

		setTimeout(function () { setShowAddedToCart(false) }, 1000);


	};

	const fnGetTotalAmount = () => {
		var totalAmt = 0;
		carList.forEach((val) => {
			totalAmt = totalAmt + (val.price * val.count);
		})
		return totalAmt;

	}


	const proceedTohandle = () => {

		var checkLogin = localStorage.getItem("loginFlag");
		if (checkLogin == null || checkLogin == undefined) {
			navigate("/login");
		} else {
			setCheckoutFlag("true");
			navigate("/customerDetail");
		}

	}

	return <>
		<section className="cart-main">
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<h1>Order Summary</h1>

						<div className="cart-product">
							<h4>PRODUCTS</h4>
							<div className="product-details">

								{carList.map((x, i) => (
									<div className="product-itemdetails row ng-star-inserted" key={i}>
										<div className="leftside-icons col-2 p-0">
											<a className="product-item-photo" title="">
												<img className="pro-img" src={`${api.baseUrl}`+`${x.image}`} />
											</a>
										</div>
										<div className="rightside-details col pr-0">
											<div className="row m-0">
												<div className="product-item-name col-12 pl-0">
													<a href="#">{x.name}</a>
													<div className="item-stockqty ng-star-inserted" id="">{x.brandName}</div>
												</div>
												<div className="catag-name col-12 pl-0">
													<a href="#" rel="tag">{x.category[0]}</a>, <a href="#" rel="tag"></a>
												</div>
												<span className="action action-delete removeitem" title="Remove item" onClick={() => handleRemoveClick(i)}><i className="fa-light fa-trash-can"></i></span>
											</div>
											<div className="row m-0 mt-3">
												<div className="item-prices col-6 p-0">
													<div className="discount-val">
														<span id="row_itmdiscprice_930167"> Rs.{x.price} /-</span>
														<span className="mrp-price ng-star-inserted">Rs.{x.price + 100}/-</span>
													</div>
												</div>
												<div className="item-qty col-6 p-0 ng-star-inserted">
													<div className="pro-qty qty-upt-evt ng-star-inserted">
														<span>Qty :</span>
														<input type="number" name="count" value={x.count} className="qty" onChange={e => handleInputChange(e, i)} />
													</div>
												</div>
											</div>
											<div className="deliveryby row m-0 mt-3">
												<div className="date deldate col-12 col-md pl-0">
													<div className="deliverybetween ng-star-inserted" id="deliveryby_930167">Delivery between JUNE 10-JUNE 11</div>
													<div className="maxqtymsg"></div>
												</div>

											</div>
										</div>
									</div>


								))}
							</div>

						</div>

					</div>

					<div className="col-lg-4">
						<div className="sticky-top">
							<div className="totalamt-col">
								<h4 className="paymentdetails-title">PAYMENT DETAILS</h4>
								<div className="allcalculation">
									<div className="subtoal"><label>MRP Total</label><span id="cart_sub_total">Rs. {fnGetTotalAmount()}</span></div>
									<div className="discount cart-discount ng-star-inserted"><label>Glizmed Discount</label><span id="cart_total_disc1">- Rs.1,238.10</span></div>
									<div className="net-amount"><label>Total Amount *</label><span id="cart_netpay_amt1">Rs. {fnGetTotalAmount()}</span></div>
									<div className="save-amount cart-savings ng-star-inserted">TOTAL SAVINGS Rs.1,238.10</div>
								</div>
								<div className="process-col">
									<div className="totalamt"><span className="text">TOTAL AMOUNT</span><span className="save-price" id="cart_netpay_amt2">Rs. {fnGetTotalAmount()}</span></div>

									<div className="process-checkout"><button className="btn-checkout btn btn_to_checkout" type="button" onClick={proceedTohandle}>Proceed</button></div>
								</div>
							</div>
							<div className="taxes-txt">
								<div>
									Glizmed is a technology platform to facilitate transaction of business. The products and services are offered for sale by the sellers. The user authorizes the delivery personnel to be his agent for delivery of the goods. For
									details read <a href="#" target="_blank" rel="noopener">Terms &amp; Conditions</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Cart;
