
import "../App.css";

import React from "react";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthDataContext } from "./AuthDataProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import img1 from "../images/h1-news01.png";
import img2 from "../images/h1-new02.png";
import img3 from "../images/h1-new03.png";

import slider1 from "../images/h3-slider01.jpg";
import slider2 from "../images/h2-slider02.jpg";
import slider3 from "../images/h3-slider01.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { api } from "../utils/ApiUrl";
import axios from "axios";
import AddedToCartModal from "../utils/AddedToCartModal";
import MedicineDetailCarousel from "../utils/MedicineDetailCarousel"


function HealthProducts(props) {
    let navigate = useNavigate();

    const { fnAddToCart, setShowAddedToCart, showAddedToCart } = useAuthDataContext();

    const [healthcategory, setHealthcategory] = useState([]);

    const [showFilter, setShowFilter] = useState("");

    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");

    const [subCategoryMedicineList, setSubCategoryMedicineList] = useState([]);

    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState("");

    const [subCategoryInfo, setSubCategoryInfo] = useState("");

    const [medicineName, setMedicineName] = useState("");

    const [medicineMrp, setMedicineMrp] = useState("");

    const [medicinePackaging, setMedicinePackaging] = useState("");

    const [medicineDiscount, setMedicineDiscount] = useState("");
    const [carouselImg, setCarouselImg] = useState([]);






    const responsive1 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const handleAllProducts = () => {
        setShowFilter("filter")
    }

    const handleHealthProductsBackBtn = () => {
        setShowFilter("")
    }

    const handleClick = (value) => {
        setShowFilter("filter");
        setSelectedSubCategoryId(value._id);
        setSelectedSubCategoryName(value.subCategoryName)
        axios
            .get(`${api.baseUrl}` + `api/get-medicine-by-subcategory/${value._id}`)
            .then((response) => {


                setSubCategoryMedicineList(response.data.result);

            })
            .catch((error) => console.log(error));
    }

    const handleClickSubInfo = (healthcategory) => {


        setMedicineName(healthcategory.ProductName)
        setMedicineMrp(healthcategory.MRP)
        setMedicinePackaging(healthcategory.Packaging)
        setMedicineDiscount(healthcategory.Discount)
        setCarouselImg(healthcategory.imagesArr)


        setShowFilter("medicineDetail");
    }


    useEffect(() => {

        axios
            .get(api.baseUrl + "api/get-all-subCategory")
            .then((response) => {


                setHealthcategory(response.data.result);

            })
            .catch((error) => console.log(error));




    }, []);


    const changeSortByMedicine = (e) => {
        e.preventDefault();

        if (e.target.value === "MRP") {

            var temArr = [];
            temArr = [...subCategoryMedicineList];


            temArr.sort((a, b) => (a.MRP - b.MRP));

            setSubCategoryMedicineList(temArr);


        }

    }


    if (showFilter == "") {
        return (

            <section className="main-banner ">
                <div id="home-banner">
                    <Carousel
                        swipeable={false}
                        arrows={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive1}
                        infinite={true}
                        autoPlaySpeed={100}
                        dotListclassName=""
                        renderDotsOutside={true}
                        afterChange={(previousSlide, { currentSlide, onMove }) => {

                        }}
                    >
                        <div className="item">
                            <img src={slider1} alt="" className="banner-bg" />
                            <div className="home-banner-content">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <img src={img1} alt="img heree" />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h2>
                                                Flat 25% Off <span>Medicine Order</span>
                                            </h2>
                                            <div className="row mt-3">
                                                <div className="col-lg-6 d-flex">
                                                    <i className="fa-light fa-prescription-bottle-medical"></i>
                                                    <h4>
                                                        Win Big Offers
                                                        <br /> Every Day
                                                    </h4>
                                                </div>
                                                <div className="col-lg-6 d-flex">
                                                    <i className="fa-light fa-prescription-bottle-medical"></i>
                                                    <h4>
                                                        Win Big Offers
                                                        <br /> Every Day
                                                    </h4>
                                                </div>
                                            </div>
                                            <a href="#" className="banner-cta">
                                                <i className="fa-light fa-right-long"></i> Shop Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <img src={slider2} alt="" className="banner-bg" />
                            <div className="home-banner-content">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <img src={img2} />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h2>
                                                Flat 25% Off <span>Medicine Order</span>
                                            </h2>
                                            <div className="row mt-3">
                                                <div className="col-lg-6 d-flex">
                                                    <i className="fa-light fa-prescription-bottle-medical"></i>
                                                    <h4>
                                                        Win Big Offers
                                                        <br /> Every Day
                                                    </h4>
                                                </div>
                                                <div className="col-lg-6 d-flex">
                                                    <i className="fa-light fa-prescription-bottle-medical"></i>
                                                    <h4>
                                                        Win Big Offers
                                                        <br /> Every Day
                                                    </h4>
                                                </div>
                                            </div>
                                            <a href="#" className="banner-cta">
                                                <i className="fa-light fa-right-long"></i> Shop Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <img src={slider3} alt="" className="banner-bg" />
                            <div className="home-banner-content">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <img src={img3} />
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <h2>
                                                Flat 25% Off <span>Medicine Order</span>
                                            </h2>
                                            <div className="row mt-3">
                                                <div className="col-lg-6 d-flex">
                                                    <i className="fa-light fa-prescription-bottle-medical"></i>
                                                    <h4>
                                                        Win Big Offers
                                                        <br /> Every Day
                                                    </h4>
                                                </div>
                                                <div className="col-lg-6 d-flex">
                                                    <i className="fa-light fa-prescription-bottle-medical"></i>
                                                    <h4>
                                                        Win Big Offers
                                                        <br /> Every Day
                                                    </h4>
                                                </div>
                                            </div>
                                            <a href="#" className="banner-cta">
                                                <i className="fa-light fa-right-long"></i> Shop Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className="Healthcategory">
                    <div className="row ">
                        {healthcategory.map(healthcategory => (
                            <div key={healthcategory._id} className="col-lg-4" onClick={() => { handleClick(healthcategory) }}>
                                <div className="row border border-4 m-0 mb-4 p-2 text-center " >
                                    <div className="col-lg-3">
                                        <img src={img1} alt="img heree" />
                                    </div>
                                    <div className="col-lg-7">
                                        <h6>{healthcategory.subCategoryName}</h6>
                                    </div>
                                    <div className="col-lg-2">
                                        <span>Upto 20% Off</span>
                                    </div>

                                </div>
                            </div>

                        ))}
                    </div>
                </div>

            </section>
        )
    } else if (showFilter == "filter") {
        return (<>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item" onClick={handleHealthProductsBackBtn}>HealthCare Products</li>
                    <li className="breadcrumb-item active" aria-current="page" >current Page </li>

                </ol>
            </nav>
            <div className="healthProductFilter showProduct">
                <div className="row ">
                    <div className="col-lg-4" >
                        <h3>Filter</h3>
                        <p>{selectedSubCategoryName}</p>


                    </div>

                    <div className="col-lg-8 mainselectCategory">
                        <div className="row selectCategory " >

                            <p className="selectedSubCategoryName">{selectedSubCategoryName}</p>



                            <div className="row selectRow ">

                                <div className="col-6 col-sm-2 sortby">Sort by

                                </div>
                                <div className="col-6 col-sm-4 select" >
                                    <select

                                        className="form-control mt-2"
                                        placeholder=""

                                        onChange={(e) => {
                                            changeSortByMedicine(e);
                                        }}

                                    >
                                        <option key={"choose"}>{"--Choose --"}</option>

                                        <option key={"MRP"} value={"MRP"} >
                                            MRP

                                        </option>
                                    </select>
                                </div>

                            </div>

                        </div>

                        <div className="row subcategory">

                            {subCategoryMedicineList.map(healthcategory => (
                                <div className="col-lg-4" key={healthcategory._id} onClick={
                                    () => { handleClickSubInfo(healthcategory) }}>
                                    <div className="product-block">
                                        <div className="product-transition">
                                            <div className="product-img-wrap top-to-bottom">
                                                <div className="inner">
                                                    <div className="product-image">


                                                        {healthcategory.imagesArr === undefined ? <></> :
                                                            <img src={`${api.baseUrl}` + `${healthcategory.imagesArr[0]}`} alt="img heree" />}
                                                    </div>

                                                </div>
                                            </div>

                                            <a
                                                href="#"
                                                className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                                            ></a>
                                        </div>
                                        <div className="caption">


                                            <h3 className="product__title" >
                                                <span>{healthcategory.ProductName}</span>

                                            </h3>

                                            <span className="price">



                                                <ins>
                                                    <span className="amount">
                                                        <bdi>
                                                            <span className="currencySymbol">Rs.</span>{healthcategory.MRP}
                                                        </bdi>
                                                    </span>
                                                </ins>
                                            </span>
                                            <del aria-hidden="true">
                                                <span className="amount">
                                                    <bdi>
                                                        <span className="currencySymbol">$</span>
                                                        124.98
                                                    </bdi>
                                                </span>
                                            </del>
                                            <div className="product-footer">
                                                <div className="shop-action">
                                                    <span
                                                        onClick={() => { fnAddToCart(healthcategory) }}
                                                        data-quantity="1"
                                                        className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                        data-product_id="258"
                                                        data-product_sku="N/A-258"
                                                        aria-label="Add “Organika Salmon Collagen” to your cart"
                                                        rel="nofollow"
                                                    >
                                                        Add to cart
                                                    </span>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>

                </div>
            </div>




        </>)
    } else {
        return (<>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item" onClick={handleHealthProductsBackBtn}>HealthCare Products</li>
                    <li className="breadcrumb-item " aria-current="page" onClick={handleAllProducts} >All Products </li>
                    <li className="breadcrumb-item active" aria-current="page" >current Page </li>
                </ol>
            </nav>




            <div className="container mainDivSubCatProduct">
                <div className="selectedSubCateByProducts ">
                    <div className="row">

                        <div className="col-lg-4  ">

                            <MedicineDetailCarousel arr={carouselImg} />
                        </div>


                        {/* <div className="col-lg-5 subcategory"> */}

                        <div className="col-lg-4 product ">
                            <div className="">
                                <p></p>
                                <div className="caption  medicineinfo">
                                    <h3 className="product__title" >
                                        <span>{medicineName}</span> </h3>

                                    <span className="Packaging">

                                        <span className="Packaging">
                                            <bdi>
                                                <span className="Packaging"></span>
                                                <p className="medicinePacking">{medicinePackaging}</p>
                                            </bdi>
                                        </span>

                                    </span>


                                    <span className="price">
                                        <ins>
                                            <span className="amount">
                                                <bdi>
                                                    <span className="currencySymbol">Rs.</span>
                                                    <span>{medicineMrp}</span>
                                                </bdi>
                                            </span>
                                        </ins>
                                    </span>
                                    <span className="deliveryDate">

                                        <span className="deliveryDate">
                                            <bdi>
                                                <span className="deliveryDate"></span>
                                                <p className="deliveryDate">delivery By 31 Aug 1 sep</p>
                                            </bdi>
                                        </span>

                                    </span>




                                    <del aria-hidden="true">
                                        <span className="amount">
                                            <bdi>
                                                <span className="currencySymbol">$</span>
                                                124.98
                                            </bdi>
                                        </span>
                                    </del>
                                    <div className="product-footer ">
                                        <div className="shop-action">
                                            <span
                                                onClick={() => { fnAddToCart(healthcategory) }}
                                                data-quantity="1"
                                                className="button product_type_simple add_to_cart_button ajax_add_to_cart"
                                                data-product_id="258"
                                                data-product_sku="N/A-258"
                                                aria-label="Add “Organika Salmon Collagen” to your cart"
                                                rel="nofollow"
                                            >
                                                Add to cart
                                            </span>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>


                        {/* </div> */}

                        <div className="col-lg-4 product ">
                            <div className="product-footer ">
                                <div className="shop-action">
                                    <p> 1 item in cart</p>
                                    <span>
                                        view cart
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>)
    }
}

export default HealthProducts;
