import React from "react";
import AuthDataProvider from "./components/AuthDataProvider";
import Router from "./components/Router";
function App() {
  return (
    <div className="">
      <AuthDataProvider>
        <Router />
      </AuthDataProvider>
    </div>
  );
}

export default App;
